import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Paper,
  IconButton,
  Divider,
  Button,
  TextField,
  Collapse,
  Card,
  CardContent,
  Chip,
  Tooltip,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {TranscriptionData } from '../services/api/type';
import CustomVideoPlayer from './CustomVideoPlayer';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchIcon from '@mui/icons-material/Search';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { apiService } from './../services/api/ApiService';
import NotificationBanner from './common/NotificationBanner';
import LoadingSpinner from './common/LoadingSpinner'; // Import the LoadingSpinner
import { useParams } from 'react-router-dom';

interface ViewTranscriptorProps {
  role: string;
}

const MAX_TRANSCRIPTION_LENGTH = 300;

const ViewTranscriptor: React.FC<ViewTranscriptorProps> = ({  role }) => {
  const { id } = useParams<{ id: string }>();
  const [transcriptionData, setTranscriptionData] = useState<TranscriptionData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [mediaUrl, setMediaUrl] = useState<string | null>(null);
  const [showFullTranscription, setShowFullTranscription] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSerialization, setShowSerialization] = useState(false);
  const mediaRef = useRef<HTMLVideoElement>(null);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [videoPosition, setVideoPosition] = useState<'top-right' | 'bottom-right'>('top-right');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [notification, setNotification] = useState({
    type: '',
    title: '',
    message: '',
    open: false,
  });

  type NotificationType = 'error' | 'success' | 'warning' | 'info';

  const handleCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    apiService.setAuthToken(token);

    const fetchData = async () => {
      try {
        setLoading(true);
        const data: TranscriptionData = role === 'admin'
          ? await apiService.getAdminTranscriptionById(id)
          : await apiService.getTranscriptionById(id);
        setTranscriptionData(data);
        if (data.videoId) {
          await apiService.fetchVideo(String(data.videoId), setMediaUrl, mediaRef);
        }
        setLoading(false);
      } catch (err) {
        setError('Error al cargar los datos');
        setLoading(false);
      }
    };
    

    fetchData();
  }, [id, role]);

  const handleDownload = async (id: number) => {
    try {
      const blob = role === 'admin'
        ? await apiService.getAdminTranscriptionReportPdfById(id)
        : await apiService.getTranscriptionReportPdfById(id);

      if (!blob || !blob.size) {
        throw new Error('El archivo PDF no está disponible.');
      }

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      const getFormattedDate = () => {
        const date = new Date();
        const formattedDate = date.toISOString().slice(0, 10); // YYYY-MM-DD
        const formattedTime = date.toTimeString().slice(0, 8).replace(/:/g, '-'); // HH-MM-SS
        return `document_${formattedDate}_${formattedTime}.pdf`;
      };
      link.download = getFormattedDate();

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setNotification({
        type: 'error',
        title: 'Error de descarga',
        message: 'No se pudo descargar el archivo PDF. Por favor, intente de nuevo más tarde.',
        open: true,
      });
    }
  };

  const handleCopy = (speaker: string, startTime: string, finishTime: string, text: string) => {
    const textToCopy = `Hablante: ${speaker}\nTiempo: ${startTime} - ${finishTime}\nDetalle: ${text}`;
    navigator.clipboard.writeText(textToCopy);
    setNotification({
      type: 'success',
      title: 'Copiado',
      message: 'El texto ha sido copiado al portapapeles.',
      open: true,
    });
  };

  useEffect(() => {
    if (mediaRef.current) {
      const handleCanPlay = () => {
        console.log("Video can play, starting playback.");
      };

      mediaRef.current.addEventListener('canplay', handleCanPlay);
      return () => {
        mediaRef.current?.removeEventListener('canplay', handleCanPlay);
      };
    }
  }, [mediaUrl]);

  useEffect(() => {
    const handleLoadStart = () => {
      console.log("loadstart event triggered");
    };

    const handleLoadedData = () => {
      console.log("loadeddata event triggered");
    };

    mediaRef.current?.addEventListener('loadstart', handleLoadStart);
    mediaRef.current?.addEventListener('loadeddata', handleLoadedData);

    return () => {
      mediaRef.current?.removeEventListener('loadstart', handleLoadStart);
      mediaRef.current?.removeEventListener('loadeddata', handleLoadedData);
    };
  }, []);

  const convertTimeToSeconds = (time: string): number => {
    console.log("Converting time:", time);
    const parts = time.split(':').map(Number);
    let hours = 0, minutes = 0, seconds = 0;

    if (parts.length === 3) {
      [hours, minutes, seconds] = parts;
    } else if (parts.length === 2) {
      [minutes, seconds] = parts;
    } else {
      console.error(`Formato de tiempo incorrecto: ${time}`);
      return 0;
    }

    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    console.log("Total seconds:", totalSeconds);
    return totalSeconds;
  };

  const handleNavigateToTime = (time: string) => {
    const seekToTime = () => {
      if (mediaRef.current && mediaRef.current.readyState >= 2) {
        const seconds = convertTimeToSeconds(time);
        mediaRef.current.pause();
        mediaRef.current.currentTime = seconds;
        console.log("Current time set and playing at:", mediaRef.current.currentTime);
      } else {
        console.log("Video not ready, waiting to seek...");
        setTimeout(seekToTime, 100);
      }
    };

    seekToTime();
  };

  const toggleFullTranscription = () => {
    setShowFullTranscription(prev => !prev);
  };

  const toggleSerialization = () => {
    setShowSerialization(prev => !prev);
  };

  const notificationType: NotificationType = 
  (notification.type === 'error' || notification.type === 'success' || notification.type === 'warning') 
    ? notification.type 
    : 'warning'; // Si no coincide, se asigna 'warning'



  const filteredSerialization = transcriptionData?.serialization.filter((entry: any) =>
    entry.words.toLowerCase().includes(searchTerm.toLowerCase()) ||
    entry.speaker.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  const isTranscriptionLong = transcriptionData?.transcription.length > MAX_TRANSCRIPTION_LENGTH;
  const displayedTranscription = showFullTranscription || !isTranscriptionLong
    ? transcriptionData?.transcription
    : transcriptionData?.transcription.slice(0, MAX_TRANSCRIPTION_LENGTH) + '...';

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollToTop(window.scrollY > 300);
      setVideoPosition(window.scrollY > 300 ? 'bottom-right' : 'top-right');
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (loading) return <LoadingSpinner />; // Use LoadingSpinner component
  if (error) return <Typography color="error">{error}</Typography>;
  if (!transcriptionData) return null;

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', p: 2 }}>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
          Transcriptor / Mis Transcripciones / {transcriptionData.name}
        </Typography>

        <Paper elevation={3} sx={{ width: '100%', p: 3, mb: 4 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={8}>
              <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 1 }}>
                {transcriptionData.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Realizada el {new Date(transcriptionData.createdAt).toLocaleDateString()}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Descargar PDF">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={() => handleDownload(transcriptionData.id)}
                >
                  Descargar PDF
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>

        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
              <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                Información importante
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Número de expediente:
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {transcriptionData.numberCase}
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    ID de Video:
                  </Typography>
                  <Typography variant="body1">
                    {transcriptionData.videoId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Fecha de creación:
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {new Date(transcriptionData.createdAt).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Duración:
                  </Typography>
                  <Typography variant="body1">
                    {transcriptionData.duration}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
              <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                Temas
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {transcriptionData.topics.split(',').map((topic: string, index: number) => (
                  <Chip key={index} label={topic.trim().toUpperCase()} color="primary" variant="outlined" />
                ))}
              </Box>
            </Paper>

            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
              <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                Resumen
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body1">
                {transcriptionData.summary}
              </Typography>
            </Paper>

            <Paper elevation={3} sx={{ p: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                  Transcripción
                </Typography>
                <Tooltip title="Copiar Transcripción">
                  <IconButton onClick={() => handleCopy('', '', '', displayedTranscription)}>
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                {displayedTranscription}
              </Typography>
              {isTranscriptionLong && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={toggleFullTranscription}
                  sx={{ mt: 2 }}
                >
                  {showFullTranscription ? 'Mostrar menos' : 'Mostrar más'}
                  {showFullTranscription ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
              )}
            </Paper>

            <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                  Detalles de la transcripcion
                </Typography>
                <IconButton onClick={toggleSerialization}>
                  {showSerialization ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              

              <Collapse in={showSerialization}>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder="Buscar palabras clave"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </Box>
                <Box sx={{ mt: 2 }}>
                  {filteredSerialization.length > 0 ? (
                    filteredSerialization.map((entry: any, index: number) => (
                      <Box
                        key={index}
                        sx={{
                          p: 2,
                          mb: 2,
                          borderRadius: 1,
                          border: `1px solid ${theme.palette.divider}`,
                          display: 'flex',
                          flexDirection: 'column',
                          backgroundColor: theme.palette.background.paper,
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            Hablante: {entry.speakerTag} - Tiempo: {entry.startTime} - {entry.finishTime}
                          </Typography>
                          <IconButton onClick={() => handleCopy(entry.speakerTag, entry.startTime, entry.finishTime, entry.words)}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Box>
                        <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
                          {entry.words}
                        </Typography>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleNavigateToTime(entry.startTime)}
                          size="small"
                          sx={{ alignSelf: 'flex-start' }}
                        >
                          Ver en Video/Audio
                        </Button>
                      </Box>
                    ))
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      No se encontraron coincidencias.
                    </Typography>
                  )}
                </Box>
              </Collapse>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            {mediaUrl && (
              <Card sx={{ position: isMobile ? 'relative' : 'sticky', top: isMobile ? 0 : 90 }}>
                <CardContent>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                      Video
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <video
                      ref={mediaRef}
                      controls
                      style={{ width: '100%', height: 'auto' }}
                      src={mediaUrl}
                      onLoadedMetadata={() => console.log("Metadata loaded")}
                      onTimeUpdate={() => console.log("Current time:", mediaRef.current?.currentTime)}
                    />
                  </Box>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </Box>
      

      {showScrollToTop && (
        <IconButton
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            backgroundColor: theme.palette.background.paper,
            '&:hover': { backgroundColor: theme.palette.action.hover }
          }}
          onClick={handleScrollToTop}
        >
          <ArrowUpwardIcon />
        </IconButton>
      )}

      <NotificationBanner
        type={notificationType}
        title={notification.title}
        message={notification.message}
        open={notification.open}
        onClose={handleCloseNotification}
      />
    </Container>
  );
};

export default ViewTranscriptor;
