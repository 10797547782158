import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'; // Importa el Router
import App from './App.tsx'; // Asegúrate de que la ruta a tu archivo App es correcta
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Crea un tema de Material-UI (personaliza según sea necesario)
const theme = createTheme({
  // Puedes agregar personalizaciones aquí
  palette: {
    primary: {
      main: '#1976d2', // Color primario
    },
    secondary: {
      main: '#dc004e', // Color secundario
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Router>
  </React.StrictMode>
);
