import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Error as ErrorIcon, CheckCircle as CheckCircleIcon, Warning as WarningIcon, Close as CloseIcon } from '@mui/icons-material';
import colors from './../../styles/Colors'; // Ajusta la ruta si es necesario

type NotificationType = 'error' | 'success' | 'warning' | 'info';

interface NotificationBannerProps {
  type: NotificationType;
  title: string;  // Propiedad añadida
  message: string;
  open: boolean;
  onClose: () => void;
}

const NotificationBanner: React.FC<NotificationBannerProps> = ({ type, title, message, open, onClose }) => {
  const [show, setShow] = useState(open);

  useEffect(() => {
    if (open) {
      setShow(true);
      const timer = setTimeout(() => {
        setShow(false);
        onClose(); // Notificar al padre para cerrar el banner
      }, 5000);

      // Limpia el temporizador si el componente se desmonta o se cierra manualmente
      return () => clearTimeout(timer);
    } else {
      setShow(false);
    }
  }, [open, onClose]);

  if (!show) return null;

  const getColor = () => {
    switch (type) {
      case 'error':
        return colors.red; // Define el color rojo en tu archivo de colores
      case 'success':
        return colors.green; // Define el color verde en tu archivo de colores
      case 'warning':
        return colors.yellow; // Define el color amarillo en tu archivo de colores
      default:
        return colors.grey;
    }
  };

  const getIcon = () => {
    switch (type) {
      case 'error':
        return <ErrorIcon sx={{ mr: 1 }} />;
      case 'success':
        return <CheckCircleIcon sx={{ mr: 1 }} />;
      case 'warning':
        return <WarningIcon sx={{ mr: 1 }} />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        left: 260, // Ajustado para estar a 20px desde el borde izquierdo
        padding: 2,
        backgroundColor: getColor(),
        color: 'white',
        borderRadius: '4px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 1200, // Asegúrate de que aparezca encima de otros elementos
        display: 'flex',
        alignItems: 'center',
        width: 300, // Ajusta el ancho si es necesario
      }}
    >
      {getIcon()}
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>{title}</Typography>
        <Typography variant="body2">{message}</Typography>
      </Box>
      <IconButton color="inherit" onClick={() => { setShow(false); onClose(); }}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default NotificationBanner;
