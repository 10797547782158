import React, { useState } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  Box,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  LinearProgress,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { apiService } from './../services/api/ApiService';
import NotificationBanner from './common/NotificationBanner'; // Asegúrate de que la ruta sea correcta
import { useNavigate } from 'react-router-dom'; // Importar useNavigate

const config = {
  chunkSize: 5 * 1024 * 1024,
};

const validFormats = ['mp4', 'mp3', 'mkv', 'wav', 'asf'];
const bucketName = 'transcriptor-tribunal-mexico';
const token = localStorage.getItem('token') || '';

const NewTranscription: React.FC<{ setActiveItem: (item: string) => void }> = ({ setActiveItem }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [transcriptionName, setTranscriptionName] = useState<string>('');
  const [caseNumber, setCaseNumber] = useState<string>('');
  const [category, setCategory] = useState<string>('Derecho Administrativo');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [notification, setNotification] = useState({
    type: 'error' as 'success' | 'error',
    title: '',
    message: '',
    open: false,
  });

  const navigate = useNavigate(); // Crear una instancia de navigate

  const isFormComplete = () => {
    return transcriptionName && caseNumber && category && files.length > 0;
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);

      if (files.length + newFiles.length > 1) {
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'Puedes subir un máximo de 1 archivo.',
          open: true,
        });
        return;
      }

      const validFiles = newFiles.filter(file => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        return validFormats.includes(fileExtension || '');
      });

      if (validFiles.length !== newFiles.length) {
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'Algunos archivos tienen un formato no soportado y no fueron agregados.',
          open: true,
        });
      }

      setFiles(validFiles.length > 0 ? [...files, ...validFiles] : files);
    }
  };

  const handleFileRemove = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    if (files.length === 0) return;

    const file = files[0];
    const totalChunks = Math.ceil(file.size / config.chunkSize);

    setIsUploading(true);
    setErrorMessage('');
    setUploadProgress(0);

    try {
      // Upload chunks
      for (let i = 0; i < totalChunks; i++) {
        const start = i * config.chunkSize;
        const end = Math.min(start + config.chunkSize, file.size);
        const blob = file.slice(start, end);

        await apiService.uploadChunk(file, i, totalChunks, bucketName, blob);
        setUploadProgress(((i + 1) / totalChunks) * 100);
        console.log(`Chunk ${i + 1} of ${totalChunks} uploaded successfully`);
      }

      // Finalize upload
      const video = await apiService.finalizeUpload(file.name, totalChunks, bucketName);
      console.log('File upload finalized successfully');

      if (video.id) {
        // Create transcription
        await apiService.createTranscription(transcriptionName, caseNumber, video.id);
        setActiveItem('Mis Transcripciones');
        navigate('/listTranscriptor'); // Navegar a la ruta después de cambiar el estado
        
        // Finish upload progress and clear state
        setUploadProgress(100);
        setNotification({
          type: 'success',
          title: 'Éxito',
          message: 'Transcripción creada exitosamente.',
          open: true,
        });
      } else {
        throw new Error('No se pudo obtener el ID del video.');
      }
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        // Handle specific 409 error
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'Ya existe un video con el mismo nombre. Por favor, elige otro nombre o video.',
          open: true,
        });
      } else {
        // Handle other errors
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'Error al generar la transcripción. Intenta de nuevo.',
          open: true,
        });
      }
      console.error('Error uploading file:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
      <Container maxWidth="lg">
        <Box sx={{ p: 4, backgroundColor: 'white', borderRadius: 2 }}>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Transcriptor/Nueva transcripción
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '40px',
              fontWeight: 600,
              lineHeight: '48.76px',
              textAlign: 'left'
            }}
            gutterBottom
          >
            Nueva transcripción
          </Typography>
          {/* Description */}
          <Typography variant="body2" color="textSecondary" paragraph sx={{ mb: 3 }}>
            Esta herramienta está específicamente diseñada para transcribir sesiones judiciales. Para utilizarla correctamente, ingresa el nombre de la sesión, el número de expediente y selecciona la categoría correspondiente. Luego, sube el archivo de audio o video relacionado de la sesión judicial, una vez procesado, el resultado estará disponible en la pestaña “Mis transcripciones”.
          </Typography>

          <Box component="form" noValidate autoComplete="off" sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Nombre de la transcripción"
                  variant="outlined"
                  margin="normal"
                  value={transcriptionName}
                  onChange={(e) => setTranscriptionName(e.target.value)}
                  sx={{ height: '56px' }} // Ajusta la altura para coincidir
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Número de expediente"
                  variant="outlined"
                  margin="normal"
                  value={caseNumber}
                  onChange={(e) => setCaseNumber(e.target.value)}
                  sx={{ height: '56px' }} // Ajusta la altura para coincidir
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Select
                  fullWidth
                  displayEmpty
                  variant="outlined"
                  margin="dense"
                  sx={{ height: '56px', mt: 2, mb: 1, fontStyle: 'normal', fontWeight: 'normal' }}
                  value={category}
                  onChange={(e) => setCategory(e.target.value as string)}
                >
                  <MenuItem disabled value="" sx={{ fontStyle: 'normal' }}>
                    <em>Categoría</em>
                  </MenuItem>
                  <MenuItem value="Derecho Penal">Derecho Penal</MenuItem>
                  <MenuItem value="Derecho Civil">Derecho Civil</MenuItem>
                  <MenuItem value="Derecho Mercantil">Derecho Mercantil</MenuItem>
                  <MenuItem value="Derecho Administrativo">Derecho Administrativo</MenuItem>
                  <MenuItem value="Derecho Laboral">Derecho Laboral</MenuItem>
                  <MenuItem value="Derecho Constitucional">Derecho Constitucional</MenuItem>
                  <MenuItem value="Derecho Internacional">Derecho Internacional</MenuItem>
                  <MenuItem value="Derecho Ambiental">Derecho Ambiental</MenuItem>
                  <MenuItem value="Derecho Agrario">Derecho Agrario</MenuItem>
                </Select>
              </Grid>
            </Grid>

            <Box sx={{ borderRadius: 2, p: 3, textAlign: 'center', cursor: 'pointer', mb: 3, backgroundColor: '#f0f0f0' }}>
              <input
                accept="audio/*,video/*"
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
                onChange={handleFileUpload}
              />
              <label htmlFor="file-upload">
                <CloudUploadIcon sx={{ fontSize: 50, color: '#9e9e9e' }} />
                <Typography variant="h6" color="textSecondary">
                  Subir audio o video
                </Typography>
              </label>
              <List sx={{ mt: 2 }}>
                {files.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={file.name} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleFileRemove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Box>

            {isUploading && <LinearProgress sx={{ mb: 2 }} variant="determinate" value={uploadProgress} />}

            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isUploading || !isFormComplete()}
              fullWidth // Ocupar todo el ancho
              sx={{
                height: '56px', // Mantener altura constante
                fontFamily: 'Montserrat',
                fontWeight: 600,
                fontSize: '20px',
                mb: 3,
              }}
            >
              {isUploading ? 'Subiendo...' : 'Subir Archivo y Generar Transcripción'}
            </Button>
          </Box>

          <NotificationBanner
            type={notification.type}
            title={notification.title}
            message={notification.message}
            open={notification.open}
            onClose={handleCloseNotification}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default NewTranscription;
