import React from 'react';
import { Box, Typography, Button, Modal, Fade, Backdrop } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface SessionClosedProps {
  open: boolean; // To control the visibility of the modal
  onClose: () => void; // Optional prop to handle additional close functionality
}

const SessionClosed: React.FC<SessionClosedProps> = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('userProfile'); // Clear user profile from localStorage
    navigate('/login'); // Redirect user to the login page
    if (onClose) onClose(); // Call onClose if provided
  };

  return (
    <Modal
      open={open}
      onClose={onClose} // Close modal on backdrop click
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 9999,
            color: '#fff',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              bgcolor: 'white',
              p: 4,
              borderRadius: '8px',
              textAlign: 'center',
              boxShadow: 3,
            }}
          >
            <Typography variant="h2" sx={{ color: '#002E74', mb: 2 }}>
              <i className="fas fa-bed" style={{ marginRight: '8px' }}></i>
              <span style={{ fontSize: '1.5rem' }}>zz</span>
            </Typography>
            <Typography variant="body1" sx={{ color: '#002E74', fontWeight: 'bold', mb: 1 }}>
              Por motivos de seguridad, cerramos su sesión.
            </Typography>
            <Typography variant="body2" sx={{ color: '#4A4A4A', mb: 4 }}>
              Vuelva a iniciar sesión para seguir.
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#002E74',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#002E74',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mx: 'auto',
                mt: 2,
              }}
              onClick={handleLogout} // Logout on button click
            >
              Inicia sesión
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SessionClosed;
