// src/routes/PrivateRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import NotFoundPage from '../pages/NotFoundPage'; // Asegúrate de la ruta correcta

interface PrivateRouteProps {
  children: React.ReactNode;
  userRole?: string;
  requiredRole?: string[];
  requiredPermissions?: string[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  userRole,
  requiredRole = [],
  requiredPermissions = [],
}) => {
  // Verificar autenticación básica
  
  const storedUserRole = userRole || JSON.parse(localStorage.getItem('userProfile') || '{}')?.role;
  const isAuthenticated = !!storedUserRole;
  // Obtener permisos del usuario del localStorage con error handling
  let userPermissions: string[] = [];
  let userProfile = JSON.parse(localStorage.getItem('userProfile') || '[]');
  try {
    userPermissions = JSON.parse(localStorage.getItem('userPermissions') || '[]');
    userRole = userProfile.role;
  } catch (error) {
    console.error("Error parsing user permissions from localStorage", error);
  }
  
  // Verificar si el usuario tiene el rol requerido
  const hasRequiredRole = requiredRole.length === 0 || requiredRole.includes(userRole || '');
  
  // Verificar si el usuario tiene los permisos requeridos
  const hasRequiredPermissions = requiredPermissions.length === 0 ||
  requiredPermissions.every(permission => userPermissions.includes(permission));
    console.log("User Role from localStorage:", userRole);
    console.log("User Permissions from localStorage:", userPermissions);
    console.log("Required Role:", requiredRole);
    console.log("Required Permissions:", requiredPermissions);
    
  if (!isAuthenticated) {
    console.log("isAuthenticated");
    return <Navigate to="/login" replace />;
  }

  if (!hasRequiredRole || !hasRequiredPermissions) {
    // Redirigir a la página de "No Encontrado"
    return <NotFoundPage />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
