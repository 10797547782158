// src/components/Navbar.tsx
import React, { useState, useCallback } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, Box, Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Home as HomeIcon,
  Draw as DrawIcon,
  AutoAwesome as AutoAwesomeIcon,
  Rule as RuleIcon,
  Widgets as WidgetsIcon,
  AccountCircle as AccountCircleIcon,
  ExitToApp as ExitToAppIcon,
  Support as SupportIcon,
  AddCircle as AddCircleIcon,
  List as ListIcon,
  ExpandLess,
  ExpandMore,
  AdminPanelSettings as AdminPanelSettingsIcon,
  Assignment as AssignmentIcon,
} from '@mui/icons-material';
import logo from './../assets/Logo_Tribunal_Federal_de_Justicia.png';
import useAvailability from '../hooks/useAvailability';

interface NavbarProps {
  activeItem: string;
  onItemSelect: (item: string) => void;
  role?: string;
}

const Navbar: React.FC<NavbarProps> = ({ activeItem, onItemSelect }) => {
  const navigate = useNavigate();
  //console.log('Permisos disponibles:', activeItem);
  
  // Obtener el perfil de usuario directamente del localStorage
  const getUserProfile = useCallback(() => {
    try {
      const profile = localStorage.getItem('userProfile');
      return profile ? JSON.parse(profile) : null;
    } catch (error) {
      console.error('Error parsing userProfile from localStorage:', error);
      return null;
    }
  }, []);

  // Estado para obtener el perfil de usuario y permisos
  const userProfile = getUserProfile();
  const userPermissions: string[] = JSON.parse(localStorage.getItem('userPermissions') || '[]');

  // Uso del hook useAvailability
  const availability = useAvailability(userProfile, userPermissions);

  const [openTranscriptor, setOpenTranscriptor] = useState(false);
  const [openInteligencia, setOpenInteligencia] = useState(false);
  const isAdmin = userProfile?.role === 'admin';

  const handleLogout = useCallback(() => {
    localStorage.clear();
    navigate('/login');
  }, [navigate]);

  const handleMenuToggle = useCallback((setter: React.Dispatch<React.SetStateAction<boolean>>) => {
    setter(prev => !prev);
  }, []);

  // Estilos memoizados para mejor rendimiento
  const getItemStyle = useCallback((itemName: string) => ({
    backgroundColor: activeItem === itemName ? '#002E74' : 'inherit',
    color: activeItem === itemName ? '#ffffff' : '#AAAAAA',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '14px',
    borderRadius: '4px',
    margin: '8px 0',
    '&:hover': {
      backgroundColor: '#002E74',
      color: '#ffffff',
      borderRadius: '4px',
    },
    border: 'none',
  }), [activeItem]);

  const listItemTextStyle = useCallback((itemName: string) => ({
    fontSize: '14px',
    color: activeItem === itemName ? '#ffffff' : '#AAAAAA',
    fontFamily: 'Montserrat, sans-serif',
  }), [activeItem]);

  const listItemIconStyle = {
    minWidth: '35px',
    color: '#AAAAAA',
  };

  const salirButtonStyle = {
    backgroundColor: activeItem === 'Salir' ? '#D32F2F' : 'inherit',
    color: '#D32F2F',
    borderRadius: '4px',
    '&:hover': {
      color: '#ffffff',
      borderRadius: '4px',
    },
  };

  // Componente de elemento de menú memoizado
  const MenuItem = useCallback(({ 
    text, 
    icon, 
    onClick, 
    isVisible = true 
  }: { 
    text: string; 
    icon: JSX.Element; 
    onClick: () => void; 
    isVisible?: boolean;
  }) => {
    if (!isVisible) return null;

    return (
      <ListItem 
        button 
        sx={getItemStyle(text)} 
        onClick={() => {
          onClick();
          onItemSelect(text);
        }}
      >
        <ListItemIcon sx={{ 
          color: activeItem === text ? '#ffffff' : '#AAAAAA', 
          ...listItemIconStyle 
        }} >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} sx={listItemTextStyle(text)} />
      </ListItem>
    );
  }, [getItemStyle, listItemTextStyle, onItemSelect, activeItem]);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: '20%',
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: '20%',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
        overflow: 'hidden',
      }}
    >
      <Box sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        overflowY: 'auto', 
        paddingTop: '60px' 
      }}>
        {/* Logo */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          padding: '0 80px 60px' 
        }}>
          <img
            src={logo}
            alt="Logo"
            style={{
              width: '220px',
              height: '194.22px',
              objectFit: 'contain',
            }}
          />
        </Box>

        {/* Menú principal */}
        <List sx={{ listStyle: 'none', margin: '16px', padding: 0 }}>
          <MenuItem 
            text="Home" 
            icon={<HomeIcon />} 
            onClick={() => navigate('/')} 
          />

          {isAdmin && (
            <MenuItem 
              text="Administración de Usuarios" 
              icon={<AdminPanelSettingsIcon />} 
              onClick={() => navigate('/userManagement')} 
            />
          )}

          <MenuItem 
            text="Análisis de Precedentes" 
            icon={<RuleIcon />} 
            onClick={() => navigate('/listPrecedent')} 
            isVisible={availability.analisisDePrecedente} 
          />

          {/* Menú Transcriptor */}
          {availability.transcriptor && (
            <>
              <ListItem 
                button 
                sx={getItemStyle('Transcriptor')} 
                onClick={() => handleMenuToggle(setOpenTranscriptor)}
              >
                <ListItemIcon sx={{ 
                  color: activeItem === 'Transcriptor' ? '#ffffff' : '#AAAAAA', 
                  ...listItemIconStyle 
                }}>
                  <DrawIcon />
                </ListItemIcon>
                <ListItemText 
                  primary="Transcriptor" 
                  sx={listItemTextStyle('Transcriptor')} 
                />
                {openTranscriptor ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openTranscriptor} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {isAdmin ? (
                    <MenuItem 
                      text="Todas las Transcripciones" 
                      icon={<AssignmentIcon />} 
                      onClick={() => navigate('/allTranscriptor')} 
                    />
                  ) : (
                    <>
                      <MenuItem 
                        text="Nueva Transcripción" 
                        icon={<AddCircleIcon />} 
                        onClick={() => navigate('/newTranscriptor')} 
                      />
                      <MenuItem 
                        text="Mis Transcripciones" 
                        icon={<ListIcon />} 
                        onClick={() => navigate('/listTranscriptor')} 
                      />
                    </>
                  )}
                </List>
              </Collapse>
            </>
          )}

          {/* Menú Inteligencia Documental */}
          {availability.inteligenciaDocumental && (
            <>
              <ListItem 
                button 
                sx={getItemStyle('Inteligencia Documental')} 
                onClick={() => handleMenuToggle(setOpenInteligencia)}
              >
                <ListItemIcon sx={{ 
                  color: activeItem === 'Inteligencia Documental' ? '#ffffff' : '#AAAAAA', 
                  ...listItemIconStyle 
                }}>
                  <AutoAwesomeIcon />
                </ListItemIcon>
                <ListItemText 
                  primary="Inteligencia Documental" 
                  sx={listItemTextStyle('Inteligencia Documental')} 
                />
                {openInteligencia ? <ExpandLess /> : <ExpandMore />}
              </ListItem>

              <Collapse in={openInteligencia} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <MenuItem 
                    text="Búsqueda GenAi" 
                    icon={<WidgetsIcon />} 
                    onClick={() => navigate('/genAiSearch')} 
                    isVisible={availability.busquedaGenAi} 
                  />
                  <MenuItem 
                    text="Prompt GenAi" 
                    icon={<WidgetsIcon />} 
                    onClick={() => navigate('/genAiPrompt')} 
                    isVisible={availability.promptGenAi} 
                  />
                </List>
              </Collapse>
            </>
          )}

          {/* Sección de Usuario */}
          {availability.perfil && (
            <>
              <Box sx={{ padding: '8px 0' }}>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    color: '#AAAAAA', 
                    paddingLeft: '16px', 
                    fontSize: '14px', 
                    fontFamily: 'Montserrat, sans-serif' 
                  }}
                >
                  USUARIO
                </Typography>
              </Box>
              
              <MenuItem 
                text="Perfil" 
                icon={<AccountCircleIcon />} 
                onClick={() => navigate('/profileEmployer')} 
              />
              
              <MenuItem 
                text="Soporte" 
                icon={<SupportIcon />} 
                onClick={() => navigate('/faqPage')} 
                isVisible={availability.soporte} 
              />
            </>
          )}
        </List>
      </Box>

      {/* Botón de Salir */}
      <Box sx={{ padding: '16px' }}>
        <ListItem button sx={salirButtonStyle} onClick={handleLogout}>
          <ListItemIcon sx={{ color: '#D32F2F' }}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText 
            primary="Salir" 
            sx={{ 
              fontSize: '14px', 
              color: '#D32F2F', 
              fontFamily: 'Montserrat, sans-serif' 
            }} 
          />
        </ListItem>
      </Box>
    </Drawer>
  );
};

export default Navbar;
