import React, { useState, useEffect } from 'react';
import colors from './../styles/Colors';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Typography,
  Box,
  Chip,
  Select,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableSortLabel,
  CircularProgress,
} from '@mui/material';
import { Visibility, Delete } from '@mui/icons-material';
import LoadingSpinner from './common/LoadingSpinner'; 
import {apiService } from './../services/api/ApiService';

interface Transcripcion {
  id: number;
  name: string;
  numberCase: string;
  userId: number;
  status: 'COMPLETED' | 'PROCESSING' | 'ERROR';
  updatedAt: string;
}

interface User {
  id: number;
  name: string;
  lastName: string;
}

interface AllTranscriptionsProps {
  setActiveItem: (item: string) => void;
}

const AllTranscriptions: React.FC<AllTranscriptionsProps> = ({ setActiveItem }) => {
  const [transcripciones, setTranscripciones] = useState<Transcripcion[]>([]);
  const [filteredTranscriptions, setFilteredTranscriptions] = useState<Transcripcion[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | string>(''); // Filtro por usuario
  const [searchTerm, setSearchTerm] = useState<string>(''); // Filtro por término de búsqueda
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [transcripcionToDelete, setTranscripcionToDelete] = useState<Transcripcion | null>(null);
  const [confirmName, setConfirmName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof Transcripcion>('name');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTranscriptions = async () => {
      try {
        setLoading(true);
        const data = await apiService.getAdminTranscriptions();
        setTranscripciones(data);
        setFilteredTranscriptions(data); // Inicialmente mostrar todas las transcripciones
      } catch (err) {
        setError('Error fetching transcriptions');
      } finally {
        setLoading(false);
      }
    };

    const fetchUsers = async () => {
      try {
        const data = await apiService.getUsers();
        setUsers(data);
      } catch (err) {
        setError('Error fetching users');
      }
    };

    fetchTranscriptions();
    fetchUsers();
  }, []);

  useEffect(() => {
    let filtered = transcripciones;
  
    // Apply user filter
    if (selectedUserId) {
      filtered = filtered.filter((t) => t.userId === Number(selectedUserId));
    }
  
    // Apply search filter
    if (searchTerm) {
      filtered = filtered.filter(
        (t) =>
          t.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          t.numberCase.toLowerCase().includes(searchTerm.toLowerCase()) ||
          getUserName(t.userId).toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  
    // Apply sorting
    filtered = filtered.sort((a, b) => {
      if (orderBy === 'updatedAt') {
        return order === 'asc'
          ? new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
          : new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      } else if (orderBy === 'status') {
        return order === 'asc'
          ? a.status.localeCompare(b.status)
          : b.status.localeCompare(a.status);
      } else {
        // Here, 'name' and 'numberCase' are the only other string properties
        const aValue = a[orderBy] as string; // Assert a[orderBy] as string
        const bValue = b[orderBy] as string; // Assert b[orderBy] as string
        return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
    });
  
    setFilteredTranscriptions(filtered);
  }, [selectedUserId, searchTerm, transcripciones, order, orderBy]);
  

  const handleRequestSort = (property: keyof Transcripcion) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'PROCESSING':
        return { background: '#FFE082', color: '#FF8F00', label: 'En proceso' }; // Amarillo
      case 'ERROR':
        return { background: '#FFCDD2', color: '#D32F2F', label: 'Error' }; // Rojo
      case 'COMPLETED':
        return { background: '#C6F6D5', color: '#2e7d32', label: 'Completado' }; // Verde
      case 'IN_PROGRESS':
        return { background: '#BBDEFB', color: '#1976d2', label: 'En progreso' }; // Azul
      case 'HIDDEN':
        return { background: '#E0E0E0', color: '#757575', label: 'Oculto' }; // Gris
      default:
        return { background: '#F5F5F5', color: '#9E9E9E', label: 'Desconocido' }; // Gris claro
    }
  };

  const handleOpenDeleteModal = (transcripcion: Transcripcion) => {
    setTranscripcionToDelete(transcripcion);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setTranscripcionToDelete(null);
    setConfirmName('');
  };

  const handleConfirmDelete = () => {
    if (transcripcionToDelete && confirmName === transcripcionToDelete.name) {
      // Lógica para eliminar la transcripción
      console.log(`Eliminando transcripción: ${transcripcionToDelete.name}`);
      handleCloseDeleteModal();
    } else {
      // Mensaje de error o validación
      alert('El nombre de la transcripción no coincide.');
    }
  };

  // const handleViewDetails = (transcripcion: Transcripcion) => {
  //   setActiveItem(`Vista Transcripciones:${transcripcion.id}`);
  // };

  const handleViewDetails = (transcripcion: Transcripcion) => {
    if (transcripcion.status !== 'PROCESSING') {
      navigate(`/transcriptor/${transcripcion.id}`);
    }
  };

  const getUserName = (userId: number) => {
    const user = users.find(user => user.id === userId);
    return user ? `${user.name} ${user.lastName}` : 'Desconocido';
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <TextField
          placeholder="Busca por nombre, número de expediente, transcriptor, instancia, palabras clave, etc."
          variant="outlined"
          fullWidth
          sx={{ mr: 2 }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {/* <Select
          value={selectedUserId}
          onChange={(e) => setSelectedUserId(e.target.value as number)}
          displayEmpty
          sx={{ mr: 2 }}
        >
          {users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.name} {user.lastName}
            </MenuItem>
          ))}
        </Select> */}
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="tabla de transcripciones">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Nombre
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'numberCase'}
                  direction={orderBy === 'numberCase' ? order : 'asc'}
                  onClick={() => handleRequestSort('numberCase')}
                >
                  Número de expediente
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'updatedAt'}
                  direction={orderBy === 'updatedAt' ? order : 'asc'}
                  onClick={() => handleRequestSort('updatedAt')}
                >
                  Fecha de actualización
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'status'}
                  direction={orderBy === 'status' ? order : 'asc'}
                  onClick={() => handleRequestSort('status')}
                >
                  Estado
                </TableSortLabel>
              </TableCell>
              <TableCell>Transcriptor</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTranscriptions.length === 0 ? (
              <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
                <TableCell colSpan={6}>
                  <Typography align="center">Sin transcripciones...</Typography>
                </TableCell>
              </TableRow>
            ) : (
              filteredTranscriptions.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.numberCase}</TableCell>
                  <TableCell>{new Date(row.updatedAt).toLocaleDateString()}</TableCell>
                  <TableCell>
                    {row.status === 'PROCESSING' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', animation: 'blink 1.5s infinite' }}>
                        <CircularProgress size={24} />
                        <Typography sx={{ ml: 1 }}>Procesando...</Typography>
                      </Box>
                    ) : (
                      <Chip label={getStatusColor(row.status).label} sx={{
                        backgroundColor: getStatusColor(row.status).background,
                        color: getStatusColor(row.status).color,
                      }} size="small" />
                    )}
                  </TableCell>
                  <TableCell>{getUserName(row.userId)}</TableCell>
                  <TableCell>
                    <IconButton  onClick={() => handleViewDetails(row)} sx={{ color: 'black' }}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteModal(row)} sx={{ color: 'red' }}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {filteredTranscriptions.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              sx={{ mr: 1 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
            <Typography variant="body2">Transcripciones por página</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ mr: 2 }}>
              {page}-{rowsPerPage} de {filteredTranscriptions.length} transcripciones
            </Typography>
            <Pagination
              count={Math.ceil(filteredTranscriptions.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          </Box>
        </Box>
      )}

      <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DialogTitle>Eliminar transcripción</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            ¿Estás seguro de que quieres eliminar esta transcripción: <strong>{transcripcionToDelete?.name}</strong>?
          </Typography>
          <TextField
            fullWidth
            label="Escribe el nombre de la transcripción para confirmar"
            value={confirmName}
            onChange={(e) => setConfirmName(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} sx={{ backgroundColor: 'grey', color: 'white' }}>Cancelar</Button>
          <Button onClick={handleConfirmDelete} sx={{ backgroundColor: 'red', color: 'white' }}>Eliminar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AllTranscriptions;
