import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Paper, CircularProgress, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/id-tfja-h-white.webp';
import logoIustitia from '../assets/ius-logo-prec@2x.png';
import { apiService } from '../services/api/ApiService';
import NotificationBanner from '../components/common/NotificationBanner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';

// Styled components
const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  backgroundColor: '#f7fafc',
  padding: 0,
}));

const Header = styled(Box)(({ theme }) => ({
  backgroundColor: '#8a8f95',
  padding: theme.spacing(2),
  textAlign: 'center',
  color: 'white',
}));

const Main = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

const FormWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  width: '100%',
  maxWidth: '400px',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
}));

const Footer = styled(Box)(({ theme }) => ({
  backgroundColor: '#8a8f95',
  color: 'white',
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const LoadingOverlay = styled(Box)(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const InputWrapper = styled(Box)(() => ({
  position: 'relative',
  marginBottom: '16px',
}));

const IconWrapper = styled('span')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '45px',
  height: '46px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#f0f0f0',
  color: '#666',
}));

const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  height: '46px',
  padding: '12px 12px',
  paddingLeft: '55px',
  border: '1px solid #ccc',
  borderRadius: theme.shape.borderRadius,
  outline: 'none',
  fontSize: '16px',
  '&:focus': {
    borderColor: '#002E74',
  },
}));

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    type: 'error',
    title: '',
    message: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    const savedEmail = localStorage.getItem('rememberedEmail');
    if (savedEmail) setEmail(savedEmail);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');
    setNotification({ open: false, type: 'error', title: '', message: '' });
  
    const trimmedEmail = email.trim();
    const trimmedPassword = password.trim();
  
    try {
      // Intentar autenticación con Microsoft primero
      let response;
      try {
        console.log("apiService.microsoftLogin")
        response = await apiService.microsoftLogin(trimmedEmail, trimmedPassword);
        console.log(response.code)
      } catch (microsoftError) {
        if (response?.status === 404) {
          console.log("apiService.login")
          response = await apiService.login({ email: trimmedEmail, password: trimmedPassword });
        } else {
          console.error('Error en Microsoft Login:', microsoftError);
          //throw microsoftError;
          response = await apiService.login({ email: trimmedEmail, password: trimmedPassword });
        }
      }
      
  
      if (response?.token) {
        const { token, refreshToken } = response;
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
  
        const userProfile = await apiService.getUserProfile();
        const validationResponse = await apiService.validateToken(token);
  
        const userRole = validationResponse.roles.includes('Manager') ? 'admin' : 'user';
        userProfile.role = userRole;
  
        if (userProfile.status === 'DELETED') {
          setNotification({
            open: true,
            type: 'error',
            title: 'Usuario Inexistente',
            message: 'Revise su usuario o comuníquese con el administrador.',
          });
          localStorage.clear();
          return;
        }
  
        localStorage.setItem('userProfile', JSON.stringify(userProfile));
        localStorage.setItem('userPermissions', JSON.stringify(validationResponse.accelerators));
  
        // Redirección según el rol y estado del usuario
        if (userRole === 'admin') {
          console.log("Entro a admin");
          navigate('/dashboard');
        } else if (userRole === 'user') {
          console.log("Entro a user");
          userProfile.status !== 'ACTIVE'
            ? navigate('/session-inactive')
            : navigate('/dashboard');
        } else {
          navigate('/');
        }
      } else {
        throw new Error('Token no recibido');
      }
    } catch (error) {
      if (error.response) {
        setNotification({
          open: true,
          type: 'error',
          title: 'Error de Inicio de Sesión',
          message: error.response.data.message || 'Error al iniciar sesión. Verifica tus credenciales.',
        });
      } else if (error.request) {
        setNotification({
          open: true,
          type: 'error',
          title: 'Error de Conexión',
          message: 'No se pudo conectar con el servidor. Verifique su conexión a internet.',
        });
      } else {
        setNotification({
          open: true,
          type: 'error',
          title: 'Error Desconocido',
          message: 'Ocurrió un error inesperado. Intente nuevamente.',
        });
      }
      setPassword('');
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <StyledContainer maxWidth={false} disableGutters>
      <Header>
        <img src={logo} alt="Government Seal" style={{ width: '400px', height: 'auto' }} />
      </Header>
      <Box display="flex" alignItems="center" justifyContent="center" sx={{ py: 4, backgroundColor: '#f7fafc' }}>
        <img src={logoIustitia} alt="Logo Iustitia" style={{ width: '200px', height: 'auto' }} />
      </Box>
      <Main>
        <FormWrapper elevation={3}>
          <Typography variant="h5" align="center" gutterBottom>
            Iniciar Sesión
          </Typography>
          {error && <Typography variant="body2" color="error" align="center" paragraph>{error}</Typography>}
          <form onSubmit={handleSubmit}>
            <InputWrapper>
              <IconWrapper>
                <FontAwesomeIcon icon={faUser} size="sm" />
              </IconWrapper>
              <StyledInput
                type="email"
                placeholder="Correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </InputWrapper>
            <InputWrapper>
              <IconWrapper>
                <FontAwesomeIcon icon={faLock} size="sm" />
              </IconWrapper>
              <StyledInput
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </InputWrapper>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ py: 1.5, fontWeight: 'bold', backgroundColor: '#002E74', '&:hover': { backgroundColor: '#001F4D' } }}
              disabled={isLoading}
            >
              INGRESAR
            </Button>
          </form>
        </FormWrapper>
      </Main>
      <Footer>
        <Typography variant="body2">TFJA 2024 | Xertica.ai</Typography>
        <Typography variant="body2">Versión: 1.0</Typography>
      </Footer>
      {isLoading && (
        <LoadingOverlay>
          <CircularProgress size={60} />
        </LoadingOverlay>
      )}
      <NotificationBanner
        type={"error"}
        title={notification.title}
        message={notification.message}
        open={notification.open}
        onClose={() => setNotification((prev) => ({ ...prev, open: false }))}
      />
    </StyledContainer>
  );
};

export default LoginForm;
