import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface LoadingSpinnerProps {
  message?: string;
  logo?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ message = "Loading...", logo }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
      }}
    >
      <Box
        sx={{
          position: 'relative', 
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {logo && (
          <img
            src={logo}
            alt="Loading Logo"
            style={{
              width: '80px',
              height: '80px',
              position: 'absolute', // Esto coloca el logo en el centro
              zIndex: 1,
            }}
          />
        )}
        <CircularProgress
          size={100}
          sx={{
            color: '#1976d2',
          }}
        />
      </Box>
      <Typography
        variant="h6"
        sx={{
          marginTop: 2,
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '16px',
          fontWeight: 600,
          textAlign: 'center',
          color: '#ffffff'
        }}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default LoadingSpinner;
