import React, { useState, useEffect, ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import { AppBar, Toolbar, Box, Avatar, Popover, Link, Typography, Modal, Backdrop, Fade, Paper, CircularProgress, Menu, MenuItem } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { apiService } from './../services/api/ApiService';
import profileImage from '../assets/images_profile.jpeg'; // Imagen de perfil por defecto
import { ActiveItem } from '../services/api/type';  

// Styled components
const WarningIconWrapper = styled(WarningIcon)({
  color: 'red',
});

// Estilos personalizados
const headerStyles = {
  display: 'flex',
  alignItems: 'center',
  padding: '10px 20px',
  borderBottom: '1px solid #ddd',
  minHeight: '41px',
};

const searchContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
};

const searchInputStyles = {
  border: 'none',
  outline: 'none',
  fontSize: '16px',
  padding: '5px',
  marginLeft: '10px',
  width: '100%',
};

const searchIconStyles = {
  fontSize: '18px',
  color: '#333',
};

const profileContainerStyles = {
  display: 'flex',
  alignItems: 'center',
};

const profileImageStyles = {
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  marginLeft: '10px',
};

const profileNameStyles = {
  marginRight: '10px',
  fontSize: '16px',
  color: '#333',
};

// Types
interface TopBarProps {
  setActiveItem: (item: ActiveItem) => void; 
}

interface SearchResponse {
  results: any[]; 
}

// Component
const TopBar: React.FC<TopBarProps> = ({ setActiveItem }) => {
  const [userName, setUserName] = useState<string>(''); // Nombre del usuario
  const [userAvatar, setUserAvatar] = useState<string>(''); // Imagen del usuario
  const [userPermissions, setUserPermissions] = useState<string[]>([]); // Permisos del usuario
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const [searchAnchorEl, setSearchAnchorEl] = useState<null | HTMLElement>(null);
  const [searchResults, setSearchResults] = useState<{ id: string; title: string; summary: string; file: string }[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Cargar el perfil del usuario y permisos desde localStorage
    const profile = localStorage.getItem('userProfile');
    const permissions = localStorage.getItem('userPermissions');

    if (profile) {
      const { name, lastName, avatar } = JSON.parse(profile);
      setUserName(`${name} ${lastName}`);
      setUserAvatar(avatar);
    }

    if (permissions) {
      setUserPermissions(JSON.parse(permissions));
    }
  }, []);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = async () => {
    if (searchQuery.trim() === '') return;

    setLoading(true);
    setSearchAnchorEl(document.getElementById('search-field'));

    try {
      const results = await apiService.searchReports(searchQuery);
      const formattedResults = results.results
        .filter((item: any) => item.document.derivedStructData.snippets[0]?.snippet_status === "SUCCESS")
        .map((item: any) => ({
          id: item.id,
          title: item.document.derivedStructData.title,
          summary: item.document.derivedStructData.extractive_answers[0]?.content || 'No summary available',
          file: item.document.derivedStructData.link,
        }));
      setSearchResults(formattedResults);
    } catch (error) {
      console.error('Error searching for reports:', error);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    setActiveItem('Perfil');
    navigate('/profileEmployer');
    handleMenuClose();
  };

  const handleLogoutClick = () => {
    localStorage.clear(); // Elimina todos los datos almacenados en localStorage
    navigate('/login');
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleDownload = async (fileUrl: string) => {
    try {
      const blob = await apiService.getReportPdf(fileUrl);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      const date = new Date();
      const formattedDate = date.toISOString().slice(0, 10);
      const formattedTime = date.toTimeString().slice(0, 8).replace(/:/g, '-');
      const fileName = `document_${formattedDate}_${formattedTime}.pdf`;

      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  return (
    <>
      <AppBar position="static" color="default" elevation={1} sx={{ bgcolor: 'white' }}>
        <Toolbar sx={headerStyles}>
        <Box sx={searchContainerStyles}>
  {/* Mostrar campo de búsqueda solo si el usuario tiene el permiso SearchAI */}
  {userPermissions.includes('SearchAI') && (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <SearchIcon sx={searchIconStyles} />
      <input
        id="search-field"
        placeholder="Search AI..."
        type="text"
        value={searchQuery}
        onChange={handleSearchChange}
        onKeyDown={handleSearchKeyDown}
        style={searchInputStyles}
      />
    </Box>
  )}
</Box>

          {/* Contenedor de perfil y controles de usuario */}
          <Box sx={profileContainerStyles}>
            <span style={profileNameStyles}>{userName || "Usuario Anónimo"}</span>
            <Avatar alt={userName} src={userAvatar || profileImage} onClick={handleMenuOpen} style={profileImageStyles} />
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleProfileClick}>Perfil</MenuItem>
              <MenuItem onClick={handleLogoutClick}>Cerrar Sesión</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopBar;
