// src/App.tsx
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppRoutes from './routes/index'; // Ensure this path is correct
import { AuthProvider } from './context/AuthContext'; // Import the AuthProvider

const theme = createTheme({
    palette: {
        primary: {
            main: '#002E74',
        },
        secondary: {
            main: '#8a8f95',
        },
        background: {
            default: '#f7fafc',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
            fontWeight: 700,
        },
    },
});

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider> {/* Wrap your routes with AuthProvider */}
                <AppRoutes />
            </AuthProvider>
        </ThemeProvider>
    );
};

export default App;
