import React, { useState, useEffect } from 'react';
import moment from 'moment'; // Importa moment
import { useNavigate } from 'react-router-dom';
import colors from './../styles/Colors';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Typography,
  Box,
  Chip,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableSortLabel,
  CircularProgress, // Importa CircularProgress
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import {
  Visibility,
  Delete,
} from '@mui/icons-material';
import { apiService } from './../services/api/ApiService';
import LoadingSpinner from './../components/common/LoadingSpinner';

interface Transcripcion {
  id: number;
  name: string;
  numberCase: string;
  videoId: number;
  userId: number;
  companyId: number;
  status: 'COMPLETED' | 'PROCESSING' | 'ERROR' | 'IN_PROGRESS' | 'HIDDEN';
  updatedAt: string;
  createdAt: string;
}

interface ListTranscriptorProps {
  setActiveItem: (item: string) => void;
}

const ListTranscriptor: React.FC<ListTranscriptorProps> = ({ setActiveItem }) => {
  const [transcripciones, setTranscripciones] = useState<Transcripcion[]>([]);
  const [filteredTranscriptions, setFilteredTranscriptions] = useState<Transcripcion[]>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [transcripcionToDelete, setTranscripcionToDelete] = useState<Transcripcion | null>(null);
  const [confirmName, setConfirmName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof Transcripcion>('createdAt');
  const [autoRefresh, setAutoRefresh] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTranscriptions = async () => {
      try {
        setLoading(true);
        const data = await apiService.getTranscriptions();
        setTranscripciones(data);
        // Filtra las transcripciones para excluir las de estado 'HIDDEN'
        const filtered = data.filter(transcripcion => transcripcion.status !== 'HIDDEN');
        setFilteredTranscriptions(filtered);

        const hasInProgress = data.some(transcripcion => transcripcion.status === 'IN_PROGRESS');
        setAutoRefresh(hasInProgress);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          setError('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.');
          window.location.href = '/login'; // Redirigir a la página de login
        } else {
          window.location.href = '/session-closed';
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTranscriptions();
  }, []);

  useEffect(() => {
    if (autoRefresh) {
      const intervalId = setInterval(async () => {
        try {
          const data = await apiService.getTranscriptions();
          setTranscripciones(data);
          const filtered = data.filter(transcripcion => transcripcion.status !== 'HIDDEN');
          setFilteredTranscriptions(filtered);
        } catch (err) {
          setError('Error al obtener las transcripciones.');
        }
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, [autoRefresh]);

  useEffect(() => {
    const sorted = [...filteredTranscriptions].sort((a, b) => {
      if (a.status === 'IN_PROGRESS' && b.status !== 'IN_PROGRESS') return -1;
      if (a.status !== 'IN_PROGRESS' && b.status === 'IN_PROGRESS') return 1;
      return order === 'asc'
        ? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    setFilteredTranscriptions(sorted);
  }, [order, transcripciones]);

  const handleRequestSort = (property: keyof Transcripcion) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Resetear a la primera página
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'PROCESSING':
        return { background: '#FFE082', color: '#FF8F00', label: 'En proceso' }; // Amarillo
      case 'ERROR':
        return { background: '#FFCDD2', color: '#D32F2F', label: 'Error' }; // Rojo
      case 'COMPLETED':
        return { background: '#C6F6D5', color: '#2e7d32', label: 'Completado' }; // Verde
      case 'IN_PROGRESS':
        return { background: '#BBDEFB', color: '#1976d2', label: 'En progreso' }; // Azul
      case 'HIDDEN':
        return { background: '#E0E0E0', color: '#757575', label: 'Oculto' }; // Gris
      default:
        return { background: '#F5F5F5', color: '#9E9E9E', label: 'Desconocido' }; // Gris claro
    }
  };
  
  const handleOpenDeleteModal = (transcripcion: Transcripcion) => {
    setTranscripcionToDelete(transcripcion);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setTranscripcionToDelete(null);
    setConfirmName('');
  };

  const handleConfirmDelete = async () => {
    if (transcripcionToDelete && confirmName === transcripcionToDelete.name) {
      try {
        const updatedTranscription = {
          name: transcripcionToDelete.name,
          numberCase: transcripcionToDelete.numberCase,
          status: 'HIDDEN'
        };

        await apiService.updateTranscriptionById(transcripcionToDelete.id, updatedTranscription);
        console.log(`Transcripción actualizada a estado 'HIDDEN': ${transcripcionToDelete.name}`);

        // Actualiza la lista de transcripciones después del cambio
        setTranscripciones((prev) =>
          prev.map((t) =>
            t.id === transcripcionToDelete.id ? { ...t, status: 'HIDDEN' } : t
          )
        );
        // Filtra las transcripciones para excluir las de estado 'HIDDEN'
        setFilteredTranscriptions((prev) =>
          prev.filter((t) => t.id !== transcripcionToDelete.id)
        );
        handleCloseDeleteModal();
      } catch (err) {
        if (err.response && err.response.status === 401) {
          setError('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.');
          window.location.href = '/login'; // Redirigir a la página de login
        } else {
          setError('Error al intentar eliminar la transcripción.');
        }
      }
    } else {
      alert('El nombre de la transcripción no coincide.');
    }
  };

  const handleViewDetails = (transcripcion: Transcripcion) => {
    if (transcripcion.status !== 'PROCESSING') {
      navigate(`/transcriptor/${transcripcion.id}`);
    }
  };

  if (loading) return <LoadingSpinner message="Cargando transcripciones..." />;
  if (error) return <Typography color="error">{error}</Typography>;

  const totalPages = Math.ceil(filteredTranscriptions.length / rowsPerPage);
  const currentTranscriptionStart = (page - 1) * rowsPerPage + 1;
  const currentTranscriptionEnd = Math.min(currentTranscriptionStart + rowsPerPage - 1, filteredTranscriptions.length);
  
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="body2" color="textSecondary">
        Transcriptor/Mis transcripciones
      </Typography>

      <Typography
  variant="h4"
  sx={{
    fontFamily: 'Montserrat',
    fontSize: '40px',
    fontWeight: 600,
    lineHeight: '48.76px',
    textAlign: 'left'
  }}
  gutterBottom
>
Mis transcripciones
</Typography>
      {/* <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
        Mis transcripciones
      </Typography> */}
      <Typography variant="body2" color="textSecondary" paragraph>
      En esta sección, podrás ver todas las transcripciones que has realizado. Utiliza la barra de búsqueda para encontrar transcripciones por nombre, número de expediente u otros criterios. Para visualizar una transcripción, haz clic en el ícono del ojo. Si deseas eliminar alguna, simplemente haz clic en el ícono de la papelera.
      </Typography>

      <TextField
        fullWidth
        placeholder="Buscar transcripciones..."
        variant="outlined"
        margin="normal"
        onChange={(e) => {
          const searchValue = e.target.value.toLowerCase();
          const filtered = transcripciones.filter((transcripcion) =>
            transcripcion.name.toLowerCase().includes(searchValue) ||
            transcripcion.numberCase.toLowerCase().includes(searchValue)
          );
          setFilteredTranscriptions(filtered);
          setPage(1); // Resetear a la primera página al buscar
        }}
      />

      {/* Nuevo Control de Paginación y Selección de Filas */}
      

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'createdAt'}
                  direction={orderBy === 'createdAt' ? order : 'asc'}
                  onClick={() => handleRequestSort('createdAt')}
                >
                  Fecha
                </TableSortLabel>
              </TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Número de Expediente</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTranscriptions
              .slice((page - 1) * rowsPerPage, page * rowsPerPage)
              .map((transcripcion) => (
                <TableRow key={transcripcion.id}>
                  <TableCell>{moment(transcripcion.createdAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                  <TableCell>{transcripcion.name}</TableCell>
                  <TableCell>{transcripcion.numberCase}</TableCell>
                  <TableCell>
                    <Chip
                      label={getStatusColor(transcripcion.status).label}
                      sx={{
                        backgroundColor: getStatusColor(transcripcion.status).background,
                        color: getStatusColor(transcripcion.status).color,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                  <IconButton onClick={() => handleViewDetails(transcripcion)} sx={{ color: 'black' }}>
            <Visibility />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteModal(transcripcion)} sx={{ color: 'red' }}>
            <Delete />
          </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
        <FormControl variant="outlined" size="small">
          <InputLabel>Transcripciones por página</InputLabel>
          <Select
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            label="Transcripciones por página"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
        </FormControl>
        <Typography color="textSecondary">
          {currentTranscriptionStart}-{currentTranscriptionEnd} de {filteredTranscriptions.length} transcripciones
        </Typography>
        <Box>
          <IconButton onClick={() => setPage(page > 1 ? page - 1 : 1)}>
            <i className="fas fa-chevron-left"></i>
          </IconButton>
          <Typography color="textSecondary">{page} de {totalPages} páginas</Typography>
          <IconButton onClick={() => setPage(page < totalPages ? page + 1 : totalPages)}>
            <i className="fas fa-chevron-right"></i>
          </IconButton>
        </Box>
      </Box>

      {/* Modal de confirmación de eliminación */}
      <Dialog open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro de que deseas eliminar la transcripción "{transcripcionToDelete?.name}"?
            Escribe el nombre para confirmar.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre de la transcripción"
            fullWidth
            variant="outlined"
            value={confirmName}
            onChange={(e) => setConfirmName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="primary">Cancelar</Button>
          <Button onClick={handleConfirmDelete} color="primary">Eliminar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ListTranscriptor;
