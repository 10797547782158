import React, { useState } from 'react';
import { Box, TextField, Button, Avatar, Grid, IconButton, Typography, Tooltip } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { apiService } from './../services/api/ApiService'; 
import { UserProfile } from './../services/api/type'; 
import LoadingSpinner from './common/LoadingSpinner'; 
import NotificationBanner from './common/NotificationBanner'; 

const MAX_FILE_SIZE = 1048576; // 1 MB
const MIN_IMAGE_DIMENSIONS = 150; // Dimensiones mínimas
const MAX_IMAGE_WIDTH = 500; // Ancho máximo para la imagen redimensionada
const MAX_IMAGE_HEIGHT = 500; // Alto máximo para la imagen redimensionada

const NewUserForm: React.FC<{ onSubmit: (newUser: UserProfile) => Promise<void>; onClose: () => void; }> = ({ onSubmit, onClose }) => {
  const [user, setUser] = useState<UserProfile>({
    id: 0,
    username: '',
    email: '',
    password: '',
    googleId: null,
    name: '',
    lastName: '',
    avatar: '',
    status: 'ACTIVE',
    role: '',
    roleId: '',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    confirmPassword: '',
    postalCode: '',
    phone: '',
    curp: '',
    cargo: '',
    estado: '',
    alcaldiaMunicipio: '',
    barrioColonia: '',
    codigoArea: '',
    city: '',
    accessLevel: '',
    telefono: ''
  });

  const [avatarPreview, setAvatarPreview] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    type: 'success' as 'success' | 'error',
    title: '',
    message: '',
    open: false,
  });
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target as HTMLInputElement;
    setUser(prevUser => ({ ...prevUser, [name]: value }));
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      // Verificar el tamaño del archivo
      if (file.size > MAX_FILE_SIZE) {
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'El archivo es demasiado grande. Debe ser menor a 1MB.',
          open: true,
        });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const { width, height } = img;

          // Verificar si la imagen es demasiado pequeña
          if (width < MIN_IMAGE_DIMENSIONS || height < MIN_IMAGE_DIMENSIONS) {
            setNotification({
              type: 'error',
              title: 'Error',
              message: `La imagen es demasiado pequeña. Debe ser al menos de ${MIN_IMAGE_DIMENSIONS}x${MIN_IMAGE_DIMENSIONS} píxeles.`,
              open: true,
            });
            return;
          }

          // Redimensionar y comprimir la imagen si es necesario
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          let newWidth = width;
          let newHeight = height;

          // Ajustar dimensiones manteniendo la proporción
          if (width > MAX_IMAGE_WIDTH || height > MAX_IMAGE_HEIGHT) {
            if (width > height) {
              newWidth = MAX_IMAGE_WIDTH;
              newHeight = (height * MAX_IMAGE_WIDTH) / width;
            } else {
              newHeight = MAX_IMAGE_HEIGHT;
              newWidth = (width * MAX_IMAGE_HEIGHT) / height;
            }
          }

          canvas.width = newWidth;
          canvas.height = newHeight;

          if (ctx) {
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
            canvas.toBlob(
              (blob) => {
                if (blob && blob.size > MAX_FILE_SIZE) {
                  setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'La imagen redimensionada aún es demasiado grande. Intenta una imagen más pequeña.',
                    open: true,
                  });
                  return;
                }

                const newFileReader = new FileReader();
                newFileReader.onloadend = () => {
                  setAvatarPreview(newFileReader.result as string);
                  setUser(prevUser => ({ ...prevUser, avatar: newFileReader.result as string }));
                };
                if (blob) newFileReader.readAsDataURL(blob);
              },
              'image/jpeg', // Cambiar a formato JPEG
              0.7 // Nivel de calidad (0.7 = 70% de la calidad original)
            );
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (user.password !== user.confirmPassword) {
      setConfirmPasswordError('Las contraseñas no coinciden.');
      return;
    }

    const { confirmPassword, ...userData } = user;
    const requiredUserData = {
      username: userData.username,
      email: userData.email,
      password: userData.password,
      name: userData.name,
      lastName: userData.lastName,
      avatar: userData.avatar || ''
    };

    try {
      setLoading(true);
      await apiService.addUser(requiredUserData);
      onClose();  // Cerrar el formulario después de agregar el usuario
      setNotification({
        type: 'success',
        title: 'Éxito',
        message: 'Usuario creado exitosamente.',
        open: true,
      });
    } catch (error) {
      console.error('Error al crear el usuario:', error);
      setNotification({
        type: 'error',
        title: 'Error',
        message: 'Error al crear el usuario.',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ padding: 4 }}>
      {loading && <LoadingSpinner />}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 3 }}>
        <Typography component="h2" variant="h6" sx={{ mb: 2 }}>Adicionar Usuario</Typography>
        <Avatar sx={{ width: 150, height: 150, borderRadius: '4px', backgroundColor: '#f0f0f0', border: '1px solid #dcdcdc' }} src={avatarPreview || undefined}>
          {avatarPreview ? null : "IMG"}
        </Avatar>
        <input accept="image/*" style={{ display: 'none' }} id="avatar-upload" type="file" onChange={handleImageChange} />
        <label htmlFor="avatar-upload">
          <Tooltip title="Subir imagen de perfil">
            <IconButton color="primary" component="span"><PhotoCamera /></IconButton>
          </Tooltip>
        </label>
      </Box>

      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {/* Contenedor 1 */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField variant="outlined" required fullWidth name="name" placeholder="Escribe el nombre aquí" value={user.name} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField variant="outlined" required fullWidth name="lastName" placeholder="Escribe el apellido aquí" value={user.lastName} onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <TextField variant="outlined" required fullWidth name="username" placeholder="Escribe el documento aquí" value={user.username} onChange={handleChange} />
          </Grid>
        </Grid>

        {/* Contenedor 2 */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField variant="outlined" required fullWidth name="email" placeholder="Escribe el e-mail aquí" value={user.email} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField variant="outlined" required fullWidth name="password" type="password" label="Contraseña" placeholder="Escribe la contraseña aquí" value={user.password} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField variant="outlined" required fullWidth name="confirmPassword" type="password" label="Confirmar contraseña" placeholder="Confirma la contraseña" value={user.confirmPassword} onChange={handleChange} error={!!confirmPasswordError} helperText={confirmPasswordError} />
          </Grid>
        </Grid>

        {/* Botones */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button variant="outlined" color="secondary" sx={{ width: '48%' }} onClick={onClose}>Cancelar</Button>
          <Button variant="contained" color="primary" type="submit" sx={{ width: '48%' }}>Crear Usuario</Button>
        </Box>
      </Box>

      <NotificationBanner
        type={notification.type}
        title={notification.title}
        message={notification.message}
        open={notification.open}
        onClose={handleCloseNotification}
      />
    </Box>
  );
};

export default NewUserForm;
