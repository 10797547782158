import axios, { AxiosError, AxiosInstance } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import fs from 'fs';
import {
  AuthResponse,
  LoginCredentials,
  RegisterData,
  ValidateTokenResponse,
  UserProfile,
  VideoData,
  Precedent,
  SearchResponse,
  Video
} from './type';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const PRECEDENT_LIST_URL = process.env.REACT_APP_PRECEDENT;
//const UPLOAD_PDF_URL = process.env.REACT_APP_UPLOAD_PDF_URL;
//const COMMENTS_URL = process.env.REACT_APP_COMMENTS_URL;

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
//const navigate = useNavigate();

class ApiService {
  private api: AxiosInstance;
  private isRefreshing = false;
  private failedQueue: Array<{
    resolve: (value: unknown) => void;
    reject: (reason?: any) => void;
  }> = [];

  private navigate: NavigateFunction;

  // constructor() {
  //   this.api = axios.create({
  //     baseURL: BASE_URL,
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   });
  constructor() {
    this.api = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Set up the navigation function
    //this.navigate = navigate;

    // Configurar el token de autenticación si está disponible en localStorage
    const { token } = this.getStoredTokens();
    if (token) {
      this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    this.setupInterceptors();
  }

  private setupInterceptors() {
    this.api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
  
        if (error.response?.status === 401 && !originalRequest._retry) {
          if (this.isRefreshing) {
            return new Promise((resolve, reject) => {
              this.failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers['Authorization'] = `Bearer ${token}`;
                return this.api(originalRequest);
              })
              .catch((err) => Promise.reject(err));
          }
  
          originalRequest._retry = true;
          this.isRefreshing = true;
  
          try {
            const { token } = await this.refreshAccessToken();
            this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            this.processQueue(null, token);
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return this.api(originalRequest);
          } catch (err) {
            // Aquí se maneja el error cuando el token no se puede refrescar
            this.processQueue(err, null);
            
            // Redirigir al usuario a la página de sesión cerrada
            this.navigate('/session-closed'); // Asegúrate de que 'navigate' esté definido para redirigir
  
            return Promise.reject(err);
          } finally {
            this.isRefreshing = false;
          }
        }
  
        return Promise.reject(error);
      }
    );
  }
  

  private processQueue(error: any, token: string | null = null) {
    this.failedQueue.forEach((promise) => {
      if (token) {
        promise.resolve(token);
      } else {
        promise.reject(error);
      }
    });

    this.failedQueue = [];
  }

  private storeTokens(token: string, refreshToken: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  private getStoredTokens() {
    return {
      token: localStorage.getItem('token'),
      refreshToken: localStorage.getItem('refreshToken'),
    };
  }

  private handleApiError(error: any): never {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        throw new Error(`API Error: ${axiosError.response.status} - ${JSON.stringify(axiosError.response.data)}`);
      } else if (axiosError.request) {
        throw new Error('No response received from the server. Please check your network connection.');
      } else {
        throw new Error(`Error setting up the request: ${axiosError.message}`);
      }
    }
    throw error;
  }

  private async refreshAccessToken(): Promise<AuthResponse> {
    const { refreshToken } = this.getStoredTokens();
    
    if (!refreshToken) {
      console.log("No refresh token available")
      throw new Error('No refresh token available');
    }

    try {
      // Configurar el encabezado de autorización para la solicitud de refresco
      this.api.defaults.headers.common['Authorization'] = `Bearer ${refreshToken}`;
      const response = await this.api.post('/auth/refresh-token');
      const { token, refreshToken: newRefreshToken } = response.data;
      this.storeTokens(token, newRefreshToken);
      return response.data;
    } catch (error) {
      console.log("Failed to refresh access token")
      throw new Error('Failed to refresh access token');
    }
  }

  // Authentication methods
  async validateToken(token: string): Promise<ValidateTokenResponse> {
    try {
      const response = await this.api.get('/users/validate-token', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async register(data: RegisterData): Promise<AuthResponse> {
    try {
      const response = await this.api.post('/auth/register', data);
      const { token, refreshToken } = response.data;
      this.storeTokens(token, refreshToken);
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async login(credentials: LoginCredentials): Promise<AuthResponse> {
    try {
      console.log({credentials})
      const response = await this.api.post('/auth/login', credentials);
      console.log({response})
      const { token, refreshToken } = response.data;
      this.storeTokens(token, refreshToken);
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    delete this.api.defaults.headers.common['Authorization'];
    
    this.navigate('/session-closed'); // Cambia a la ruta de tu componente SessionClosed
  }

  // User methods
  async getUserProfile(): Promise<UserProfile> {
    try {
      const response = await this.api.get('/users/me');
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async updateUserProfile(profileData: Partial<UserProfile>): Promise<{ username: string, name: string, lastName: string, avatar: string }> {
    try {
      // Transformar el objeto profileData para ajustarse al formato requerido
      const transformedData = {
        username: profileData.username || '',
        name: profileData.name || '',
        lastName: profileData.lastName || '',
        avatar: profileData.avatar || ''
      };
  
      const response = await this.api.patch('/users/me', transformedData);
      return response.data;
    } catch (error) {
      this.handleApiError(error);
      throw error;  // No silenciar el error
    }
  }
  // Admin methods
  async getUsers(): Promise<UserProfile[]> {
    try {
      const response = await this.api.get('/admin/users');
      // Filtrar usuarios que no estén marcados como "DELETED"
      return response.data.filter((user: UserProfile) => user?.status !== 'DELETED');
    } catch (error) {
      this.handleApiError(error);
      return []; // Devolver un array vacío en caso de error
    }
  }
  
  async microsoftLogin(email: string, password: string): Promise<AuthResponse> {
    try {
      console.log("microsoftLogin")
      const response = await this.api.post('/microsoft', { email, password });
      console.log({response})
      const { token, refreshToken } = response.data;
  
      // Almacenar los tokens en localStorage
      this.storeTokens(token, refreshToken);
      
      return response.data;
    } catch (error) {
      console.log("microsoftLogin",error)
      this.handleApiError(error);
    }
  }
  

  async updateUser(userId: number, profileData: Partial<UserProfile>): Promise<UserProfile> {
    try {
      const response = await this.api.patch(`/admin/users/${userId}`, profileData);
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async changePassword(userId: number, newPassword: string): Promise<void> {
    try {
      await this.api.post(`/admin/users/${userId}/change-password`, { password: newPassword });
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async addUser(userData: {
    username: string;
    email: string;
    password: string;
    name: string;
    lastName: string;
    avatar: string;
  }): Promise<UserProfile> {
    try {
      const response = await this.api.post('/admin/users', userData);
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async getUserById(userId: number): Promise<UserProfile> {
    try {
      const response = await this.api.get(`/admin/users/${userId}`);
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async deleteUser(userId: number): Promise<void> {
    try {
      await this.api.delete(`/admin/users/${userId}`);
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async updateUserStatus(userId: number, status: string): Promise<void> {
    try {
      await this.api.patch(`/admin/users/${userId}/status`, { status });
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async getGCPCredentials(): Promise<any> {
    try {
      const response = await this.api.get('/admin/gcp/credentials');
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async getAdminRoles(): Promise<string[]> {
    try {
      const response = await this.api.get('/admin/roles');
      
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async updateUserRole(userId: number, roleId: number): Promise<void> {
    try {
      await this.api.patch(`/admin/users/${userId}/roles/${roleId}`);
    } catch (error) {
      this.handleApiError(error);
    }
  }

  // Video methods
  async uploadVideo(videoData: VideoData): Promise<void> {
    try {
      await this.api.post('/videos', videoData);
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async getVideos(): Promise<Video[]> {
    try {
      const response = await this.api.get('/videos');
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async getTranscriptionReportPdfById(id: number): Promise<Blob> {
    try {
      const response = await this.api.get(`/transcriptions/${id}/report-pdf`, {
        responseType: 'blob', // Important to handle binary data
      });
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async getAdminTranscriptionReportPdfById(id: number): Promise<Blob> {
    try {
      const response = await this.api.get(`/admin/transcriptions/${id}/report-pdf`, {
        responseType: 'blob', // Importante para manejar datos binarios
      });
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }
  

  async getVideoById(id: string): Promise<Video> {
    try {
      const response = await this.api.get(`/videos/${id}`);
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async deleteVideoById(id: string): Promise<void> {
    try {
      await this.api.delete(`/videos/${id}`);
    } catch (error) {
      this.handleApiError(error);
    }
  }

  // Transcription methods
  async getTranscriptions(): Promise<any[]> {
    try {
      const response = await this.api.get('/transcriptions');
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async getAdminTranscriptions(): Promise<any[]> {
    try {
      const response = await this.api.get('/admin/transcriptions');
      // Filtrar las transcripciones con status diferente a "HIDDEN"
      const filteredTranscriptions = response.data.filter((transcription: any) => transcription.status !== 'HIDDEN');
      
      // Ordenar por createdAt de manera descendente (más reciente primero)
      const sortedTranscriptions = filteredTranscriptions.sort((a: any, b: any) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
  
      return sortedTranscriptions;
    } catch (error) {
      this.handleApiError(error);
    }
  }
  

  async getAdminTranscriptionById(id: string): Promise<any> {
    try {
      const response = await this.api.get(`/admin/transcriptions/${id}`);
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async getTranscriptionById(id: string): Promise<any> {
    try {
      const response = await this.api.get(`/transcriptions/${id}`);
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async updateTranscriptionById(id: number, data: Partial<{ name: string; numberCase: string; status: string }>): Promise<any> {
    try {
      const response = await this.api.patch(`/transcriptions/${id}`, data);
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  // Search methods
  async searchReports(query: string): Promise<SearchResponse> {
    try {
      const response = await this.api.post('/search', { query });
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async getReportPdf(url: string): Promise<Blob> {
    try {
      const response = await this.api.get('/search/report-pdf', {
        responseType: 'blob',
        params: { url },
      });
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  // Google Auth method
  async getGoogleAuth(): Promise<any> {
    try {
      const response = await this.api.get('/auth/google');
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  // Video streaming method
  async fetchVideo(videoId: string, setVideoUrl: (url: string) => void, videoRef: React.RefObject<HTMLVideoElement>): Promise<void> {
    try {
      const rangeHeader = 'bytes=0-';
      const response = await fetch(`${BASE_URL}/videos/${videoId}/stream`, {
        headers: {
          'Range': rangeHeader,
          'Content-Type': 'video/mp4',
          'Company-ID': '1',
          'Authorization': `Bearer ${this.getStoredTokens().token}`,
        },
      });

      if (response.ok) {
        const videoBlob = await response.blob();
        const videoUrl = URL.createObjectURL(videoBlob);
        setVideoUrl(videoUrl);
        if (videoRef.current) {
          videoRef.current.src = videoUrl;
        }
      } else {
        throw new Error(`Error fetching video: ${response.statusText}`);
      }
    } catch (error) {
      this.handleApiError(error);
    }
  }

  // Video upload methods
  async uploadChunk(file: File, chunkIndex: number, totalChunks: number, bucketName: string, blob: Blob): Promise<void> {
    const formData = new FormData();
    formData.append('file', blob, file.name);
    formData.append('chunkIndex', chunkIndex.toString());
    formData.append('bucketName', bucketName);

    for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
      try {
        await this.api.post('/videos/upload-chunk', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(`Chunk ${chunkIndex} uploaded successfully.`);
        return;
      } catch (error) {
        if (attempt < MAX_RETRIES - 1) {
          console.warn(`Chunk ${chunkIndex} failed, retrying... (${attempt + 1}/${MAX_RETRIES})`);
          await new Promise(res => setTimeout(res, RETRY_DELAY));
        } else {
          console.error(`Chunk ${chunkIndex} failed after ${MAX_RETRIES} attempts.`);
          throw error;
        }
      }
    }
  }

  async finalizeUpload(fileName: string, totalChunks: number, bucketName: string): Promise<any> {
    try {
      const response = await this.api.post('/videos/finalize-upload', {
        fileName,
        totalChunks,
        bucketName,
      });
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async createTranscription(transcriptionName: string, caseNumber: string, videoId: number): Promise<any> {
    try {
      const response = await this.api.post('/transcriptions', {
        name: transcriptionName,
        numberCase: caseNumber,
        videoId: videoId,
      }, {
        timeout: 1800000, // 30 minutes timeout
      });
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }

  // async getPrecedenteList(userId: number): Promise<Precedent[]> {
  //   try {
  //     const response = await this.api.get(`https://us-central1-xert-prod-gen-ai.cloudfunctions.net/precedente-list`, {
  //       params: { userId }
  //     });
  //     return response.data;
  //   } catch (error) {
  //     this.handleApiError(error);
  //   }
  // }

  async getPrecedenteList(userId?: number): Promise<Precedent[]> {
  try {
    // Construcción de la URL con la ruta específica y parámetros de consulta
    const response = await axios.get<Precedent[]>(`${PRECEDENT_LIST_URL}/database`, {
      params: userId ? { userId } : {}, // Agrega el parámetro `userId` si se proporciona
    });

    // Devuelve la lista de precedentes invertida si es necesario
    return response.data.reverse();
  } catch (error) {
    // Manejo de errores
    console.error("Error al obtener la lista de precedentes:", error);
    throw error; // Vuelve a lanzar el error si se necesita manejarlo en otra parte
  }
}

  
  

  // async getPrecedenteInfo(pdfFileName: string): Promise<Precedent[]> {
  //   const URL = `${PRECEDENT_LIST_URL}/database`; // Aseguramos la construcción correcta de la URL
  
  //   try {
  //     // Realizamos la petición GET con parámetros y encabezados
  //     const response = await axios.get(URL, {
  //       params: { pdf_file_name: pdfFileName },
  //       headers: {
  //         Authorization: `Bearer ${this.getStoredTokens().token}`, // Autorización si es necesaria
  //       },
  //     });
  
  //     // Verificamos el estado de la respuesta antes de procesar los datos
  //     if (response.status === 200) {
  //       const relevantPrecedents = response.data.filter(
  //         (precedent: Precedent) => precedent.relevant === "Relevante"
  //       );
  //       return relevantPrecedents; // Retornamos los precedentes relevantes
  //     } else {
  //       throw new Error(`Error en la petición: ${response.statusText}`);
  //     }
  //   } catch (error) {
  //     this.handleApiError(error); // Manejo centralizado del error
  //     throw error; // Re-lanzamos el error para permitir manejo en la capa superior
  //   }
  // }

  async getPrecedenteInfo(pdfFileName: string): Promise<Precedent[]> {
    const URL = `${PRECEDENT_LIST_URL}/database`;
  
    try {
      const response = await axios.get(URL, {
        params: { pdf_file_name: pdfFileName },
      });
  let resutl=response.data
  .filter((precedent: Precedent) => precedent.relevant === "Relevante" && precedent.hechos != null)
  .reverse(); // Si se requiere, se invierte el orden de los datos
      // Filtramos los precedentes relevantes en la misma operación de retorno
      console.log({resutl})
      return resutl
  
    } catch (error) {
      this.handleApiError(error);
      throw error; // Re-lanzamos el error para permitir su manejo en la capa superior
    }
  }
  
  

  

  // async getPrecedenteListAdmin(): Promise<Precedent[]> {
  //   const URL = `${PRECEDENT_LIST_URL}/database`; // Aseguramos la construcción correcta de la URL
  //   try {
  //     // Usamos axios para realizar la petición GET
  //     const response = await axios.get(URL, {
  //       headers: {
  //         Authorization: `Bearer ${this.getStoredTokens().token}`, // Autorización si es necesaria
  //       },
  //     });
  
  //     // Verificamos si la respuesta es válida antes de devolver los datos
  //     if (response.status === 200) {
  //       return response.data as Precedent[]; // Cast explícito a tipo Precedent[]
  //     } else {
  //       throw new Error(`Error en la petición: ${response.statusText}`);
  //     }
  //   } catch (error) {
  //     this.handleApiError(error); // Llamamos al manejo centralizado de errores
  //     throw error; // Re-lanzamos el error para manejarlo en la capa superior
  //   }
  // }

  async getPrecedenteListAdmin(): Promise<Precedent[]> {
    const URL = `${PRECEDENT_LIST_URL}/database`; // Aseguramos la construcción correcta de la URL
  
    try {
      // Realizamos la petición GET sin encabezado de autorización
      const response = await axios.get(URL);
  
      // Verificamos si la respuesta es válida antes de devolver los datos
      if (response.status === 200) {
        return response.data as Precedent[]; // Cast explícito a tipo Precedent[]
      } else {
        throw new Error(`Error en la petición: ${response.statusText}`);
      }
    } catch (error) {
      this.handleApiError(error); // Llamamos al manejo centralizado de errores
      throw error; // Re-lanzamos el error para manejarlo en la capa superior
    }
  }
  


  async sendComment(
    comment: string,
    userId: number,
    companyId: number,
    pdfFileName: string,
    servico: string
): Promise<void> {
    console.log(comment, userId, companyId, pdfFileName, servico);

    const URL = PRECEDENT_LIST_URL;
    try {
        const response = await axios.post(URL, {
            comentario: comment,
            userId: userId,       // Agregar parámetros adicionales
            companyId: companyId,
            pdf_file_name: pdfFileName,
            servico: servico
        });

        return response.data; // Si es necesario, ajusta esto para que devuelva el formato correcto
    } catch (error) {
        this.handleApiError(error); // Asegúrate de que esta función maneje el error correctamente
    }
}

  

  async getPrecedente(): Promise<Precedent[]> {
    try {
      
      const response = await this.api.get('/precedents');
      return response.data;
    } catch (error) {
      this.handleApiError(error);
    }
  }
 
  // async uploadData(
  //   file: File,
  //   title: string,
  //   numberCase: string,
  //   userId: number,
  //   companyId: number
  // ): Promise<any> {
  //   try {
  //     const formData = new FormData();
  
  //     // Añadir los campos necesarios al FormData
  //     formData.append("file", file);
  //     formData.append("name", title); // Cambiado para usar 'title' en lugar de 'name'
  //     formData.append("numberCase", numberCase);
  //     formData.append("userId", userId.toString());
  //     formData.append("companyId", companyId.toString());
  
  //     // Configurar los headers (sin especificar Content-Type, ya que fetch lo configura automáticamente)
  //     const headers = {
  //       Authorization: `Bearer ${this.getStoredTokens().token}`, // Suponiendo que hay un método para obtener tokens
  //     };
  
  //     // Realizar la solicitud POST con fetch
  //     const response = await fetch(`${PRECEDENT_LIST_URL}/upload`, {
  //       method: "POST",
  //       body: formData,
  //       headers,
  //     });
  
  //     // Manejo de respuesta
  //     if (response.ok) {
  //       const result = await response.json();
  //       console.log("Archivo subido correctamente:", result);
  //       return result; // Retorna el resultado para ser usado por la función que lo llame
  //     } else {
  //       throw new Error(`Error al subir archivo: ${response.statusText}`);
  //     }
  //   } catch (error) {
  //     this.handleApiError(error); // Método para manejar errores
  //     throw error; // Asegura que el error se propague si se necesita
  //   }
  // }
  
  async uploadData(
    file: File,
    title: string,
    numberCase: string,
    userId: number,
    companyId: number
  ): Promise<any> {
    try {
      const formData = new FormData();
  
      // Añadir los campos necesarios al FormData
      formData.append("file", file);
      formData.append("name", title); // Usar 'title' en lugar de 'name'
      formData.append("numberCase", numberCase);
      formData.append("userId", userId.toString());
      formData.append("companyId", companyId.toString());
  
      // Realizar la solicitud POST sin encabezados adicionales
      const response = await fetch(`${PRECEDENT_LIST_URL}/upload`, {
        method: "POST",
        body: formData,
      });
  
      // Manejo de respuesta
      if (response.ok) {
        const result = await response.json();
        console.log("Archivo subido correctamente:", result);
        return result; // Retorna el resultado para ser usado por la función que lo llame
      } else {
        throw new Error(`Error al subir archivo: ${response.statusText}`);
      }
    } catch (error) {
      this.handleApiError(error); // Método para manejar errores
      throw error; // Asegura que el error se propague si se necesita
    }
  }
  
  
  

  setAuthToken(token: string) {
    this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
}

export const apiService = new ApiService();  // Pass the required argument here

