// src/routes/AppRoutes.tsx
import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box, useTheme, useMediaQuery, CircularProgress, Typography } from '@mui/material';

// Componentes
import Navbar from '../components/Navbar';
import TopBar from '../components/TopBar';
import PrivateRoute from './PrivateRoute'; // Rutas privadas

// Páginas
import LoginPage from '../pages/LoginPage';
import HomePage from '../components/Home';
import SessionClosed from '../pages/SessionClosed';
import DashboardInactive from '../pages/admin/DashboardInactive';
import AllTranscriptions from '../components/AllTranscriptions';
import NewTranscriptions from '../components/NewTranscription';
import UserManagement from '../pages/admin/UserManagement';
import ListPrecedent from '../components/ListPrecedent';
import ListTranscriptor from '../components/ListTranscriptor';
import ProfileEmployer from '../components/ProfileEmployer';
import FaqPage from '../components/FaqPage';
import ViewTranscriptor from '../components/ViewTranscriptor';
import ViewPrecedent from '../components/ViewPrecedent';
import NotFoundPage from '../pages/NotFoundPage';
import useAvailability from '../hooks/useAvailability';

interface UserProfile {
  role: string;
  // Otras propiedades si las hay
}

// Define un tipo para availability
// interface Availability {
//   userManagement: boolean;
//   transcriptor: boolean;
//   inteligenciaDocumental: boolean;
//   busquedaGenAi: boolean;
//   analisisDePrecedente: boolean;
//   promptGenAi: boolean;
//   perfil: boolean;
//   ajustes: boolean;
//   salir: boolean;
//   soporte: boolean;
//   faq: boolean;
// }

const AppRoutes: React.FC = () => {
  const [activeItem, setActiveItem] = useState<string>('Home');
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [userPermissions, setUserPermissions] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  // const [availability, setAvailability] = useState<Availability>({
  //   userManagement: false,
  //   transcriptor: false,
  //   inteligenciaDocumental: false,
  //   busquedaGenAi: false,
  //   analisisDePrecedente: false,
  //   promptGenAi: false,
  //   perfil: true,
  //   ajustes: false,
  //   salir: true,
  //   soporte: true,
  //   faq: true,
  // });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const storedUserProfile = localStorage.getItem('userProfile');
    const storedUserPermissions = localStorage.getItem('userPermissions');
    
    if (storedUserProfile && storedUserPermissions) {
      try {
        setUserProfile(JSON.parse(storedUserProfile));
        setUserPermissions(JSON.parse(storedUserPermissions));
      } catch (error) {
        console.error('Error parsing localStorage data:', error);
        localStorage.clear(); // Limpia datos corruptos
      }
    }
    setLoading(false);
  }, []);
  
  const availability = useAvailability(userProfile, userPermissions);
//   // Actualiza availability en base a userProfile y userPermissions
//   useEffect(() => {
//     if (userProfile) {
//         console.log('User Profile:', userProfile);
//         console.log('User Permissions:', userPermissions);
//         setAvailability({
//             transcriptor: userPermissions.includes('Transcriptor'),
//             userManagement: userProfile.role === 'admin',
//             inteligenciaDocumental: userPermissions.includes('inteligenciaDocumental'),
//             busquedaGenAi: userPermissions.includes('busquedaGenAi'),
//             analisisDePrecedente: userPermissions.includes('Precedents'),
//             promptGenAi: userPermissions.includes('promptGenAi'),
//             perfil: true,
//             ajustes: userProfile.role === 'admin',
//             salir: true,
//             soporte: true,
//             faq: true,
//         });
//     }
// }, [userProfile, userPermissions]);



  const AuthenticatedLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Box sx={{ display: 'flex', height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <Box
        sx={{
          width: isMobile ? '100%' : '20%',
          height: isMobile ? 'auto' : '100%',
          overflow: 'auto',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Navbar
          // availability={availability}
          activeItem={activeItem}
          onItemSelect={setActiveItem}
          role={userProfile?.role}
        />
      </Box>
      <Box sx={{ flexGrow: 1, width: '80%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <TopBar setActiveItem={setActiveItem} />
        <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: theme.spacing(2), backgroundColor: '#FFFFFF' }}>
          {children}
        </Box>
      </Box>
    </Box>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'column' }}>
        <CircularProgress size={60} />
        <Typography sx={{ mt: 2 }} variant="h6">Cargando, por favor espera...</Typography>
      </Box>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/session-closed" element={<SessionClosed />} />
      <Route path="/session-inactive" element={<DashboardInactive />} />
      
      {/* Rutas autenticadas */}
      <Route path="/" element={
        <PrivateRoute userRole={userProfile?.role}>
          <AuthenticatedLayout>
            <HomePage />
          </AuthenticatedLayout>
        </PrivateRoute>
      } />

      <Route path="/dashboard" element={
        <PrivateRoute userRole={userProfile?.role} requiredRole={["admin","user"]}>
          <AuthenticatedLayout>
            <HomePage />
          </AuthenticatedLayout>
        </PrivateRoute>
      } />

      <Route path="/allTranscriptor" element={
        <PrivateRoute userRole={userProfile?.role} requiredRole={["admin"]}>
          <AuthenticatedLayout>
            <AllTranscriptions setActiveItem={setActiveItem} />
          </AuthenticatedLayout>
        </PrivateRoute>
      } />

      <Route path="/newTranscriptor" element={
        <PrivateRoute userRole={userProfile?.role} requiredRole={["admin","user"]}>
          <AuthenticatedLayout>
            <NewTranscriptions setActiveItem={setActiveItem} />
          </AuthenticatedLayout>
        </PrivateRoute>
      } />

      <Route path="/listPrecedent" element={
        <PrivateRoute userRole={userProfile?.role} requiredRole={["admin","user"]}>
          <AuthenticatedLayout>
            <ListPrecedent setActiveItem={setActiveItem} />
          </AuthenticatedLayout>
        </PrivateRoute>
      } />

      <Route path="/userManagement" element={
        <PrivateRoute userRole={userProfile?.role} requiredRole={["admin"]}>
          <AuthenticatedLayout>
            <UserManagement />
          </AuthenticatedLayout>
        </PrivateRoute>
      } />

      <Route path="/listTranscriptor" element={
        <PrivateRoute userRole={userProfile?.role} requiredRole={["admin","user"]}>
          <AuthenticatedLayout>
            <ListTranscriptor setActiveItem={setActiveItem} />
          </AuthenticatedLayout>
        </PrivateRoute>
      } />

      <Route path="/profileEmployer" element={
        <PrivateRoute userRole={userProfile?.role} requiredRole={["admin","user"]}>
          <AuthenticatedLayout>
            <ProfileEmployer />
          </AuthenticatedLayout>
        </PrivateRoute>
      } />

      <Route path="/faqPage" element={
        <PrivateRoute userRole={userProfile?.role} requiredRole={["admin","user"]}>
          <AuthenticatedLayout>
            <FaqPage />
          </AuthenticatedLayout>
        </PrivateRoute>
      } />

      <Route path="/transcriptor/:id" element={
        <PrivateRoute userRole={userProfile?.role} requiredRole={["admin","user"]}>
          <AuthenticatedLayout>
            <ViewTranscriptor role={userProfile?.role} />
          </AuthenticatedLayout>
        </PrivateRoute>
      } />

      <Route path="/precedente/:precedentName" element={
        <PrivateRoute userRole={userProfile?.role} requiredRole={["admin","user"]}>
          <AuthenticatedLayout>
            <ViewPrecedent setActiveItem={setActiveItem} />
          </AuthenticatedLayout>
        </PrivateRoute>
      } />
        
      {/* Página 404 */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
