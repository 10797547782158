import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import colors from './../styles/Colors'; // Asegúrate de la ruta correcta

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/login');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        p: 2,
        backgroundColor: '#f5f5f5', // Opcional: color de fondo para la página
      }}
    >
      <ErrorOutlineIcon sx={{ fontSize: 80, color: colors.colorPrimary, mb: 2 }} />
      <Typography variant="h3" component="h1" gutterBottom>
        404 - Página no encontrada
      </Typography>
      <Typography variant="h6" component="p" gutterBottom>
        Lo sentimos, la página que estás buscando no existe.
      </Typography>
      <Button
        variant="contained"
        sx={{ mt: 3, textTransform: 'none', backgroundColor: colors.colorPrimary }}
        onClick={handleGoHome}
      >
        Volver a la página de inicio
      </Button>
    </Box>
  );
};

export default NotFoundPage;
