import React, { useState } from 'react';
import { Card, CardContent, Typography, TextField, Button, Box, Alert, Collapse } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { apiService } from './../services/api/ApiService'; // Importamos el servicio

const FeedbackForm: React.FC = () => {
  const [comment, setComment] = useState('');
  const [success, setSuccess] = useState(false); // Estado para controlar si el comentario fue enviado con éxito
  const selectedPrecedent = JSON.parse(localStorage.getItem('selectedPrecedent') || 'null');
  const userProfile = JSON.parse(localStorage.getItem('userProfile') || 'null');
  const userId = userProfile.id;
  const pdfFileName = selectedPrecedent.pdf_file_name;
  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleSubmit = async () => {
    // Reemplaza con los valores reales
    
    const servico = 'precedente';

    try {
      // Usamos el servicio ApiService para enviar el comentario
      
      await apiService.sendComment(comment, userId, 1, pdfFileName, servico);
      setSuccess(true); // Marcamos el estado de éxito cuando se envía el comentario
      setComment(''); // Limpiar el campo de comentario
    } catch (error) {
      console.error('Error al enviar comentario:', error);
      alert('Hubo un problema al enviar tu comentario.');
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Card sx={{ maxWidth: 400, width: '100%', p: 2, boxShadow: 3 }}>
        <CardContent>
          <Box display="flex" alignItems="center" mb={2}>
            <WarningIcon color="error" sx={{ fontSize: 30, mr: 1 }} />
            <Typography variant="h6" component="h1" fontWeight="bold">
              ¿Detectaste un error en el resumen?
            </Typography>
          </Box>
          <Typography variant="body1" color="textSecondary" mb={3}>
            Si encontraste un error o inconsistencia en el resumen generado, por favor déjanos un comentario con los detalles. ¡Tu feedback es importante para mejorar nuestro servicio!
          </Typography>

          {/* Mostrar el mensaje de éxito solo cuando el comentario haya sido enviado */}
          <Collapse in={success}>
            <Alert
              severity="success"
              icon={<CheckCircleIcon fontSize="inherit" />}
              sx={{ mb: 2, backgroundColor: '#4caf50', color: '#fff' }}
            >
              <Typography fontWeight="bold">
                ¡Comentario enviado con éxito!
              </Typography>
              <Typography>
                Gracias por tu aporte. Hemos recibido tu comentario y lo revisaremos para mejorar el resumen.
              </Typography>
            </Alert>
          </Collapse>

          {/* Solo mostrar el input y el botón si el comentario no ha sido enviado */}
          {!success && (
            <>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Escribe tu comentario aquí."
                variant="outlined"
                margin="normal"
                value={comment}
                onChange={handleCommentChange}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ backgroundColor: '#002E74', py: 2, mt: 2 }}
                onClick={handleSubmit}
              >
                Enviar Comentario
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default FeedbackForm;
