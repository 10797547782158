import { grey, yellow } from "@mui/material/colors";

const colors = {
    colorPrimary: '#002E74', // 60% Fondos
    red:"#EA0026",
    grey:"#F5F7F8",
    yellow:"#F6FB7A",
    green:"#80AF81",
    textPrimary:'#fffff',
    colorPrimaryHover: '#e0e0e0',
    colorSecondary: '#f50057', // 30% Botones, etc.
    colorSecondaryHover: '#0056b3',
    colorTertiary: '#fffff', // 20% Texto
    colorTertiaryHover: '#222222',
    disabledOpacity: 0.6,
  };
  
  export default colors;
  