import React from 'react';
// import { Box, Container } from '@mui/material';
import LoginForm from '../components/LoginFormV'; // Asegúrate de que esta ruta sea correcta

const LoginPage: React.FC = () => {
  return (
    <LoginForm /> 
  );
};

export default LoginPage;
