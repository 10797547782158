// src/pages/SessionClosed.tsx

import React, { useState } from 'react';
import { Button, Typography, Box } from '@mui/material';
import SessionClosedModal from '../components/SessionClosed'; // Importar el modal

const SessionClosed: React.FC = () => {
  const [openModal, setOpenModal] = useState(true); // Estado para manejar la apertura del modal

  const handleCloseModal = () => {
    setOpenModal(false); // Cerrar el modal
  };

  return (
    <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
      {/* Modal para sesión cerrada */}
      <SessionClosedModal open={openModal} onClose={handleCloseModal} />
    </Box>
  );
};

export default SessionClosed;
