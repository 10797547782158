import React from 'react';
import { Snackbar, Alert } from '@mui/material';

interface SimpleNotificationProps {
  open: boolean;
  onClose: () => void;
  type: 'success' | 'error' | 'warning';
  message: string;
}

const SimpleNotification: React.FC<SimpleNotificationProps> = ({ open, onClose, type, message }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000} // Se cierra automáticamente después de 3 segundos
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Posición de la notificación
    >
      <Alert onClose={onClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SimpleNotification;
