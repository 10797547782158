import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TextField,
  Box,
  Typography,
  Pagination,
  MenuItem,
  Container,
} from '@mui/material';
import NewAnalysisModal from './../components/NewAnalysisModal';
import { apiService } from './../services/api/ApiService';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

// Styled components using @emotion/styled
const RelevanceTag = styled.span<{ background: string; color: string }>`
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  font-size: 0.75rem;
  padding: 4px 12px;
  border-radius: 9999px;
  display: inline-block;
`;

interface ListPrecedentProps {
  setActiveItem: (item: string) => void;
}

const ListPrecedent: React.FC<ListPrecedentProps> = ({ setActiveItem }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [precedents, setPrecedents] = useState<any[]>([]);
  const [filteredPrecedents, setFilteredPrecedents] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate(); // Inicializa useNavigate

  useEffect(() => {
    setActiveItem('Análisis de Precedentes');
    fetchPrecedents();
  }, [setActiveItem]);

  useEffect(() => {
    setFilteredPrecedents(
      precedents.filter((precedent) =>
        precedent.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, precedents]);

  const fetchPrecedents = async () => {
    try {
      const userProfile = localStorage.getItem('userProfile');
      const userData = userProfile ? JSON.parse(userProfile) : null;
  
      if (!userData) {
        console.error("No user data found in local storage");
        return;
      }
  
      let precedentsData;
      if (userData.role === 'admin') {
        // Si el usuario es admin, obtiene todos los precedentes.
        precedentsData = await apiService.getPrecedenteList();
      } else {
        // Si es un usuario normal, obtiene los precedentes del usuario específico.
        precedentsData = await apiService.getPrecedenteList(userData.id);
      }
  
      setPrecedents(precedentsData);
      console.log({ precedentsData });
    } catch (error) {
      console.error("Error fetching precedents:", error);
    }
  };
  

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleViewPrecedentOpen = (precedent: any) => {
    // Redirige al usuario a la nueva ruta usando el nombre del precedente
    localStorage.setItem('selectedPrecedent', JSON.stringify(precedent));
    navigate(`/precedente/${precedent.pdf_file_name}`);
  };

  const handleUploadSuccess = () => {
    fetchPrecedents();
    handleModalClose();
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'PROCESSING':
        return { background: '#FFE082', color: '#FF8F00', label: 'En proceso' };
      case 'ERROR':
        return { background: '#FFCDD2', color: '#D32F2F', label: 'Error' };
      case 'COMPLETED':
        return { background: '#C6F6D5', color: '#2e7d32', label: 'Completado' };
      case 'IN_PROGRESS':
        return { background: '#BBDEFB', color: '#1976d2', label: 'En progreso' };
      case 'HIDDEN':
        return { background: '#E0E0E0', color: '#757575', label: 'Oculto' };
      default:
        return { background: '#F5F5F5', color: '#9E9E9E', label: 'Desconocido' };
    }
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10)); // Corrige el segundo parámetro a 10
    setPage(1);
  };

  const paginatedData = filteredPrecedents.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  const currentPrecedentStart = (page - 1) * rowsPerPage + 1;
  const currentPrecedentEnd = Math.min(page * rowsPerPage, filteredPrecedents.length);

  return (
    <Container 
      maxWidth={false} // Permite que el contenedor ocupe todo el ancho
      disableGutters // Elimina los márgenes predeterminados
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'flex-start', // Alinea el contenido en la parte superior
        p: 3, // Agrega padding para el contenedor
        backgroundColor: '#ffffff', // Color de fondo claro
        minHeight: '100vh' // Asegura que ocupe al menos toda la altura de la pantalla
      }}
    >
      <Box display="flex" alignItems="center" mb={4} sx={{ width: '100%', justifyContent: 'space-between' }}>
      <Typography
  variant="h4"
  sx={{
    fontFamily: 'Montserrat',
    fontSize: '40px',
    fontWeight: 600,
    lineHeight: '48.76px',
    textAlign: 'left'
  }}
  gutterBottom
>
  Análisis de Precedentes
</Typography>

        <Button
          variant="contained"
          sx={{ backgroundColor: '#002E74', color: '#fff' }}
          onClick={handleModalOpen}
        >
          Nuevo análisis
        </Button>
      </Box>

      <Typography variant="body2" color="textSecondary" paragraph>
        En esta sección, podrás ver todos los Análisis de precedentes que has realizado. Utiliza la barra de búsqueda para encontrar precedentes por nombre, número de expediente u otros criterios. Para visualizar un Precedente, haz clic en el ícono del ojo. Si deseas eliminar alguna, simplemente haz clic en el ícono de la papelera.
      </Typography>

      <Box display="flex" mb={4} sx={{ width: '100%' }}>
        <TextField
          fullWidth
          placeholder="Busca por título"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <FontAwesomeIcon icon={faSearch} style={{ color: '#A0AEC0', marginRight: 8 }} />
            ),
          }}
        />
      </Box>

      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Título Análisis</TableCell>
              <TableCell>Número de expediente</TableCell>
              <TableCell>Precedentes</TableCell>
              <TableCell>Nombre del archivo PDF</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((precedent, index) => {
              const statusColor = getStatusColor(precedent.status);
              return (
                <TableRow key={index}>
                  <TableCell>{precedent.name}</TableCell>
                  <TableCell>{precedent.numberCase}</TableCell>
                  <TableCell>{precedent.num_sentencias ? precedent.num_sentencias : 0}</TableCell>
                  <TableCell>{precedent.pdf_file_name}</TableCell>
                  <TableCell>
                    <RelevanceTag background={statusColor.background} color={statusColor.color}>
                      {statusColor.label}
                    </RelevanceTag>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleViewPrecedentOpen(precedent)}
                      disabled={precedent.status !== 'COMPLETED'}
                      sx={{ color: 'black' }}
                    >
                      <FontAwesomeIcon
              icon={faEye}
              style={{ color: 'black' }} // Cambia a negro para el ícono
            />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={2} display="flex" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
      <Box>
          <TextField
            select
            label="Filas por página"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            variant="outlined"
            size="small"
          >
            {[10, 20, 30].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      
        <Pagination
          count={Math.ceil(filteredPrecedents.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
         <Typography color="textSecondary">
          {currentPrecedentStart}-{currentPrecedentEnd} de {filteredPrecedents.length} precedentes
        </Typography>
      </Box>

      <NewAnalysisModal open={modalOpen} onClose={handleModalClose} onUploadSuccess={handleUploadSuccess} />
    </Container>
  );
};

export default ListPrecedent;
