import React, { useState, useEffect } from 'react';
import Navbar from './../../components/Navbar';
import TopBar from './../../components/TopBar';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { apiService } from './../../services/api/ApiService';

interface UserProfile {
  id: number;
  username: string;
  email: string;
  password: string;
  googleId: string | null;
  name: string;
  lastName: string;
  avatar: string;
  status: 'AWAITING_APPROVAL' | 'APPROVED' | 'REJECTED';
  createdAt: string;
  updatedAt: string;
}

const DashboardInactive: React.FC = () => {
  const [activeItem, setActiveItem] = useState<string>('Home');
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [userRole, setUserRole] = useState<string>('user');
  const [isNavbarOpen, setIsNavbarOpen] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleItemClick = (item: string) => {
    setActiveItem(item);
    if (isMobile) {
      setIsNavbarOpen(false);
    }
  };

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  // Define las funcionalidades deshabilitadas para el usuario inactivo
  const availability = {
    transcriptor: false,
    userManagement: false,
    inteligenciaDocumental: false, // Correct spelling
    busquedaGenAi: false,
    analisisDePrecedente: false,
    promptGenAi: false, // Correct spelling
    perfil: false,
    ajustes: false,
    salir: true,
    soporte: false,
    faq: false, // Add missing property
  };

  useEffect(() => {
    const storedUserProfile = localStorage.getItem('userProfile');
    if (storedUserProfile) {
      setUserProfile(JSON.parse(storedUserProfile));
    }

    const token = localStorage.getItem('token');
    if (token) {
      const validateUserRole = async () => {
        try {
          const validationResponse = await apiService.validateToken(token);
          const role = validationResponse.roles.includes("Manager") ? 'admin' : 'user';
          setUserRole(role);
        } catch (error) {
          console.error('Error validating token:', error);
        }
      };

      validateUserRole();
    }
  }, []);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: isMobile ? 'column' : 'row',
      height: '100vh', 
      width: '100vw', 
      overflow: 'hidden',
      backgroundColor: theme.palette.background.default,
    }}>
      {/* Navbar */}
      <Box
        sx={{
          width: isMobile ? '100%' : '20%',
          height: isMobile ? (isNavbarOpen ? 'auto' : '0') : '100%',
          overflow: 'auto',
          transition: isMobile ? 'height 0.3s ease-in-out' : 'none',
          position: isMobile ? 'absolute' : 'relative',
          zIndex: isMobile ? 1000 : 'auto',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Navbar
          // availability={availability}
          activeItem={activeItem}
          onItemSelect={handleItemClick}
          role={userRole}
        />
      </Box>
      
      {/* Área de contenido */}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          width: isMobile ? '100%' : '80%',
        }}
      >
        {userProfile && (
          <TopBar
            
            setActiveItem={setActiveItem}
        
          />
        )}
        <Box sx={{ 
          flexGrow: 1, 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: theme.spacing(2), 
          backgroundColor: theme.palette.background.default,
        }}>
          <Box sx={{ 
            textAlign: 'center',
            maxWidth: '600px',
            backgroundColor: '#f5f5f5',
            borderRadius: '10px',
            padding: theme.spacing(4),
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          }}>
            <h1 style={{ fontSize: '24px', color: '#333333' }}>
              ¡Hola, {userProfile?.name}!
              <br/>
              {/* <span style={{ color: '#0033a0' }}>¡Nos alegra verte de nuevo!</span> */}
            </h1>
            <div style={{ fontSize: '40px', color: '#ff0000', marginBottom: '20px' }}>
              <i className="fas fa-exclamation-triangle"></i>
            </div>
            <p style={{ fontSize: '16px', color: '#333333' }}>
              Tu cuenta ha sido creada, pero <span style={{ color: '#ff0000' }}>aún no tienes acceso a las herramientas de la plataforma.</span>
            </p>
            <p style={{ fontSize: '16px', color: '#333333' }}>
              Un administrador debe autorizar tu cuenta para que puedas comenzar a utilizar todas las funcionalidades. Recibirás una notificación cuando se haya aprobado tu acceso.
            </p>
            <p style={{ fontSize: '16px', color: '#333333' }}>
              ¡Gracias por tu paciencia!
            </p>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardInactive;
